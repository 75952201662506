import { useState } from "react";
import "./styles.css";
import { FRACTIONALIZE_STATUS } from "../../hooks/useFractionlize";
import { ClipLoader } from "react-spinners";

interface ConfirmModelProps {
  fractionlizeStatus: FRACTIONALIZE_STATUS;
  setOpenConfirm: (closeModel: boolean) => void;
  convertHandler: () => void;
  batchId: number;
}

const ConfirmModal = ({
  fractionlizeStatus,
  setOpenConfirm,
  convertHandler,
  batchId,
}: ConfirmModelProps) => {
  console.log("fractionlizeStatus", fractionlizeStatus, "batchId", batchId);
  return (
    <div className="confirm_modal_background">
      <div className="confirm_modal_Container">
        {fractionlizeStatus === FRACTIONALIZE_STATUS.COMPLETE ? (
          <>
            <div className="confirm_modal_body">
              <div className="confirm-head">Congratulations!!!</div>
              <div className="font-h3">
                You have successfully converted open BCO2 batch #{batchId} into
                Buckmint CO2 Tokens (BCO2)
              </div>
            </div>
            <div className="confirm_modal_footer">
              <button
                className="confirm_modal_footer_button filled_button"
                onClick={() => setOpenConfirm(false)}
                id="cancelBtn"
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="confirm_modal_body">
              <div className="font-h3">
                Are you sure , you want to convert open BCO2 batch ID #{batchId}{" "}
                into Buckmint CO2 Tokens (BCO2)?
              </div>
            </div>
            <div className="confirm_modal_footer">
              <button
                className="confirm_modal_footer_button"
                onClick={() => setOpenConfirm(false)}
                id="cancelBtn"
              >
                No
              </button>
              <button
                className="confirm_modal_footer_button filled_button"
                onClick={() => convertHandler()}
              >
                {fractionlizeStatus === FRACTIONALIZE_STATUS.LOADING ? (
                  <>
                    <ClipLoader color="white" size={20} />
                    &nbsp; `Yes`
                  </>
                ) : (
                  `Yes`
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmModal;
