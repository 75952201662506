import { useState } from "react";
import Cross from "../../../../assets/icons/cross.png";
import "./BridgePage.css";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { ClipLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: "14px", // Adjust the font size here
  },
}));
interface BridgePageProps {
  fetchLoading: boolean;
  error: boolean;
  serialNumber: string | undefined;
  email: string | undefined;
  setSerialNumber: (serialNumber: string) => void;
  setEmail: (email: string) => void;
  refetchSerialNumber: (serialNumber: string) => void;
}

const BridgePage = ({
  fetchLoading,
  error,
  email,
  setEmail,
  serialNumber,
  setSerialNumber,
  refetchSerialNumber,
}: BridgePageProps) => {
  console.log("serialNumber", serialNumber);
  const classes = useStyles();
  return (
    <div className="bridge_page_body">
      <div className="bridge_page_upper_body">
        <h1>Bridge Transferred Credits</h1>
        <div className="bridge_page_upper_body_detail_box">
          <h1>Carbon Credit Serial Number</h1>
          <p>
            Add one or multiple serial numbers to create a batch offset. The
            serial should have the following format:
            9344-82392500-82392759-VS=CS-VCU-262-VER-BR-14-1686-01012018-31122018-0
          </p>
        </div>
      </div>
      <form>
        <div className="bridge_page_lower_body">
          <div className="bridge_page_serial">
            <input
              required
              type="text"
              name="name"
              value={serialNumber}
              onChange={(e) => {
                setSerialNumber(e.target.value);
                refetchSerialNumber(e.target.value);
              }}
            />
            {error && (
              <Tooltip
                title="serial number already used"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton>
                  <InfoIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
            {fetchLoading && <ClipLoader />}
          </div>
          <div className="bridge_page_user_details">
            <h1>Your Email</h1>
            <p>For updates on the status of your transfer</p>
            <input
              required
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BridgePage;
