import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, AppState } from "..";
import { useCallback } from "react";
import { setEmptyBatchNft } from ".";

export const useSetEmptyBatchNft = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (nftId: number | null) => {
      dispatch(setEmptyBatchNft(nftId));
    },
    [dispatch],
  );
};

export const useEmptyBatchNft = () => {
  return useSelector(
    (state: AppState) => state.utilStore.utilsStore.emptyBatchNft,
  );
};
