import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OpenBco2Batch,
  BuckmintCo2Batch,
  BuckmintPoolToken,
} from "../../types";

export interface Notes {
  openBco2Batches: OpenBco2Batch[] | null;
  buckmintCo2Batches: BuckmintCo2Batch[] | null;
  buckmintPoolTokens: BuckmintPoolToken[] | null;
}

const initialNotes: Notes = {
  openBco2Batches: null,

  buckmintCo2Batches: null,
  buckmintPoolTokens: null,
};

const tokensSlice = createSlice({
  name: "tokens",
  initialState: initialNotes,
  reducers: {
    setOpenBCO2Batches(state, { payload }: PayloadAction<OpenBco2Batch[]>) {
      state.openBco2Batches = payload;
      return state;
    },
    setBuckMintCO2Batches(
      state,
      { payload }: PayloadAction<BuckmintCo2Batch[]>,
    ) {
      state.buckmintCo2Batches = payload;
      return state;
    },
    setBuckmintPoolTokens(
      state,
      { payload }: PayloadAction<BuckmintPoolToken[]>,
    ) {
      state.buckmintPoolTokens = payload;
      return state;
    },
  },
});

export const {
  setOpenBCO2Batches,
  setBuckMintCO2Batches,
  setBuckmintPoolTokens,
} = tokensSlice.actions;

export default tokensSlice.reducer;
