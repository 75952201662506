import { useState, useRef, useMemo } from "react";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import Testnet from "../../components/Testnet/Testnet";

import "./Marketplace.css";
import "./Sell/sellCard.css";
import BuyModal from "../../components/BuyModal/BuyModel";
import TokenModal from "../../components/TokenModal/TokenModal";
import { useClickOutside } from "../../hooks/useClickOutside";

import SellSection from "./Sell";
import BuySection from "./Buy";
import SellCard from "./Sell/sellCard";
import { useAllowedTokens, useOrders } from "../../store/marketplace/hook";
import { useWeb3React } from "@web3-react/core";
import { AllowedTokenMetdata, OrdersMetaData } from "../../store/marketplace";
import {
  useBuckMintCO2Batches,
  useBuckmintPoolTokens,
} from "../../store/tokens/hook";
import { useProjects } from "../../store/projects/hooks";
import { toastErrorHandler, toastSuccessHandler } from "../../utils/toast";
import {
  ApprovalTransactionStatus,
  useAllowance,
  useApproval,
} from "../../hooks/useApprove";
import { marketPlaceContract, usdt } from "../../config/marketplace";
import { unitParser } from "../../utils";
import { SellTransactionStatus, useSell } from "../../hooks/useSell";
import { BuyTransactionStatus, useBuy } from "../../hooks/useBuyToken";
import { useBalance } from "../../hooks/useBalance";

enum TAB {
  BUY,
  SELL,
}

function Marketplace() {
  const [tab, setTab] = useState<TAB>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<AllowedTokenMetdata>();
  const [searchVal, setSearchVal] = useState<string>();
  const [isTokenModelOpen, setIsTokenModelOpen] = useState<boolean>(false);
  const [buyingAmount, setBuyingAmount] = useState<number>();
  const tokenModalRef = useRef(null);
  const [selectedToBuy, setSelectedToBuy] = useState<OrdersMetaData>();
  const [sellQuantity, setSellQuantity] = useState<number>();
  const [sellPrice, setSellPrice] = useState<number>();
  const [buyingAmountError, setBuyingAmountError] = useState<boolean>(false);
  const [tokenSelectError, setTokenSelectError] = useState<boolean>(false);
  const [sellQuantityError, setSellQuantityError] = useState<boolean>(false);
  const [sellPriceError, setSellPriceError] = useState<boolean>(false);

  const handleTab = (newTab: TAB) => {
    setTab(newTab);
  };

  // close modal
  const closeModal = () => {
    setOpenModal(false);
  };

  useClickOutside(tokenModalRef, () => setIsTokenModelOpen(false));

  const { account, chainId, active } = useWeb3React();

  const allOrders = useOrders();
  const myOrders = useMemo(() => {
    return allOrders?.filter(
      (el) => el?.sellerAddress?.toLowerCase() === account?.toLowerCase(),
    );
  }, [allOrders, account]);

  const poolToken = useBuckmintPoolTokens();

  const allowedTokens = useAllowedTokens();

  const mergedArray = poolToken.map((obj1) => {
    const matchingObj2 = allowedTokens.find(
      (obj2) => obj2.tokenAddress === obj1.tokenAddress,
    );

    return { ...obj1, ...matchingObj2 };
  });

  const selectedProject = useMemo(() => {
    return poolToken?.filter(
      (el) =>
        el?.tokenAddress?.toLowerCase() ===
        selectedToken?.tokenAddress?.toLowerCase(),
    );
  }, [selectedToken]);

  const projects = useProjects();

  const projectId = useMemo(() => {
    const keys = Object.keys(projects);
    const foundKey = keys.find(
      (key) =>
        projects[key].toString() ===
        selectedProject?.[0]?.tokenAddress.toString(),
    );
    return foundKey;
  }, [selectedProject, projects]);

  const bco2Allowance = useAllowance(
    account,
    selectedToken?.tokenAddress,
    marketPlaceContract?.[chainId],
  );

  const bco2Approval = useApproval(
    bco2Allowance,
    unitParser(sellQuantity),
    marketPlaceContract?.[chainId],
    selectedToken?.tokenAddress,
  );

  const usdtAllowance = useAllowance(
    account,
    usdt?.[chainId],
    marketPlaceContract?.[chainId],
  );

  const usdtApproval = useApproval(
    usdtAllowance,
    unitParser(buyingAmount),
    marketPlaceContract?.[chainId],
    usdt?.[chainId],
  );

  const sellResponse = useSell(
    selectedToken?.tokenAddress,
    sellPrice,
    sellQuantity,
  );

  const buyResponse = useBuy(Number(selectedToBuy?.orderId), buyingAmount);

  const usdtBalance = useBalance(usdt?.[chainId]);

  const handleBCO2Approve = async () => {
    setTokenSelectError(false);
    setSellQuantityError(false);

    if (!selectedToken) {
      setTokenSelectError(true);
      return;
    }

    if (!sellQuantity) {
      setSellQuantityError(true);
      return;
    }

    await bco2Approval.triggeredApproval();

    if (
      bco2Approval.approvalTxStatus ===
      ApprovalTransactionStatus.APPROVAL_CONFIRMED
    ) {
      toastSuccessHandler("Token approve successful");
    } else {
      toastErrorHandler("Token approve Error");
    }
  };

  const handleUSDTApprove = async () => {
    setBuyingAmountError(false);
    if (!buyingAmount) {
      setBuyingAmountError(true);
      return;
    }
    await usdtApproval.triggeredApproval();
    if (
      usdtApproval.approvalTxStatus ===
      ApprovalTransactionStatus.APPROVAL_CONFIRMED
    ) {
      toastSuccessHandler("Token approve successful");
    } else {
      toastErrorHandler("Token approve Error");
    }
  };

  const handleBuy = async () => {
    setBuyingAmountError(false);
    if (!buyingAmount) {
      setBuyingAmountError(true);
      return;
    }
    await buyResponse.triggeredBuy();

    if (buyResponse.buyTxStatus === BuyTransactionStatus.BUY_CONFIRMED) {
      toastSuccessHandler("Token buy successful");
    } else {
      toastErrorHandler("Token buying Error");
    }
  };

  const handleSell = async () => {
    setTokenSelectError(false);
    setSellQuantityError(false);
    setSellPriceError(false);

    if (!selectedToken) {
      setTokenSelectError(true);
      return;
    }

    if (!sellQuantity) {
      setSellQuantityError(true);
      return;
    }

    if (!sellPrice) {
      setSellPriceError(true);
      return;
    }

    await sellResponse.triggeredSell();

    if (sellResponse.sellTxStatus === SellTransactionStatus.SELL_CONFIRMED) {
      toastSuccessHandler("token lsted to sell");
    } else {
      toastErrorHandler("Token listing Error");
    }
  };

  return (
    <>
      <div className="marketplace_home home">
        <section className="marketplace_section1 section1">
          <Testnet />
          <ConnectWallet />
        </section>
        <section className="marketplace_section4 section4">
          <div className="tab_buttons">
            <button
              className={`buy_button btn ${tab === TAB.BUY ? "activeTab" : ""}`}
              type="button"
              onClick={() => handleTab(TAB.BUY)}
            >
              BUY
            </button>
            <button
              className={`sell_button btn ${
                tab === TAB.SELL ? "activeTab" : ""
              }`}
              type="button"
              onClick={() => handleTab(TAB.SELL)}
            >
              SELL
            </button>
          </div>
          <div className="tabs_contents">
            {tab === TAB.BUY && (
              <BuySection
                setOpenModal={setOpenModal}
                setSelectedToBuy={setSelectedToBuy}
                allListedOrder={allOrders}
              />
            )}
            {tab === TAB.SELL && (
              <div className="sell_items_wrapper">
                <SellSection
                  setIsTokenModelOpen={() =>
                    setIsTokenModelOpen(!isTokenModelOpen)
                  }
                  selectedToken={selectedToken}
                  handleList={() => handleSell()}
                  selectedProject={selectedProject?.[0]}
                  projectId={projectId}
                  quantity={sellQuantity}
                  price={sellPrice}
                  setPrice={setSellPrice}
                  setQuantity={setSellQuantity}
                  bco2Response={bco2Approval}
                  sellResponse={sellResponse}
                  handleBco2Approve={handleBCO2Approve}
                  tokenSelectError={tokenSelectError}
                  sellQuantityError={sellQuantityError}
                  sellPriceError={sellPriceError}
                />
                <div className="sell_token_cards">
                  <div className="order_section">
                    <h2>My Orders</h2>
                  </div>
                  <div className="sell_cards">
                    {myOrders?.map((el) => <SellCard el={el} />)}
                  </div>
                  {myOrders?.length <= 0 && (
                    <p className="empty_text">
                      {active && account
                        ? "There is no sell order"
                        : "Please connect wallet and switch to Polygon Mumbai"}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {openModal && (
        <BuyModal
          closeModal={() => closeModal()}
          selectedToken={selectedToBuy}
          approvalResponse={usdtApproval}
          buyResponse={buyResponse}
          handleApprove={handleUSDTApprove}
          handleBuy={handleBuy}
          amount={buyingAmount}
          setAmount={setBuyingAmount}
          usdtBalance={usdtBalance}
          buyAmountError={buyingAmountError}
        />
      )}
      {isTokenModelOpen && (
        <TokenModal
          setTokenOpenModal={setIsTokenModelOpen}
          setSearchVal={setSearchVal}
          tokenList={mergedArray}
          setSelectedToken={setSelectedToken}
          searchVal={searchVal}
        />
      )}
    </>
  );
}

export default Marketplace;
