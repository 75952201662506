import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "..";
import { useEffect } from "react";
import { setMarketplaceOrder, setAllowedToken } from ".";
import { getAllowedToken, getMarketplaceOrders } from "../../utils/graphql";
import { useAppChainId } from "../application/hook";
import { useBuckMintCO2Batches } from "../tokens/hook";
import {
  formatAllowedToken,
  filterMarketplaceOrder,
} from "../../utils/marketplace";

export const useSetAllowedToken = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appChainId = useAppChainId();

  const allBCO2Tokens = useBuckMintCO2Batches();

  useEffect(() => {
    if (!appChainId) return;
    const fetchAllowedToken = async () => {
      const allowedToken = await getAllowedToken(Number(appChainId)).then(
        (value) => value,
      );

      const filterdToken: string[] = [];
      allowedToken?.map((el) => {
        if (el?.isAllowed === true) {
          filterdToken.push(el?.id);
        }
      });

      const formatedToken = formatAllowedToken(allBCO2Tokens, filterdToken);

      if (formatedToken) {
        dispatch(setAllowedToken(formatedToken));
      }
    };
    fetchAllowedToken();
  }, [appChainId, dispatch, allBCO2Tokens]);
};

export const useSetMarketplaceOrder = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appChainId = useAppChainId();

  const allBCO2Tokens = useBuckMintCO2Batches();

  useEffect(() => {
    if (!appChainId) return;
    const fetchOrders = async () => {
      const marketplaceOrder = await getMarketplaceOrders(
        Number(appChainId),
      ).then((value) => value);

      const marketplaceOrderMetaData = filterMarketplaceOrder(
        allBCO2Tokens,
        marketplaceOrder,
      );

      if (marketplaceOrderMetaData) {
        dispatch(setMarketplaceOrder(marketplaceOrderMetaData));
      }
    };
    fetchOrders();
  }, [appChainId, allBCO2Tokens, dispatch]);
};

export const useAllowedTokens = () => {
  return useSelector((state: AppState) => state.marketplace.allowedTokens);
};

export const useOrders = () => {
  return useSelector((state: AppState) => state.marketplace.orders);
};
