import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProtocolData } from "../../types";

export interface Application {
  protocolData: ProtocolData;
}

const initialProtocol: Application = {
  protocolData: {
    totalRetired: 0,
    totalBPT: 0,
    totalBridged: 0,
  },
};

const protocolSlice = createSlice({
  name: "protocol",
  initialState: initialProtocol,
  reducers: {
    setTotalRetired(
      state,
      { payload }: PayloadAction<{ totalRetired: number }>,
    ) {
      state.protocolData.totalRetired = payload.totalRetired;
      return state;
    },
    setTotalBridged(
      state,
      { payload }: PayloadAction<{ totalBridged: number }>,
    ) {
      state.protocolData.totalBridged = payload.totalBridged;
      return state;
    },
    setTotalBPT(state, { payload }: PayloadAction<{ totalBPT: number }>) {
      state.protocolData.totalBPT = payload.totalBPT;
      return state;
    },
  },
});

export const { setTotalBridged, setTotalRetired, setTotalBPT } =
  protocolSlice.actions;

export default protocolSlice.reducer;
