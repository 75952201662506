import { useState } from "react";
import Copy from "../../../../assets/icons/Copy.png";
import One from "../../../../assets/icons/one.png";
import Two from "../../../../assets/icons/two.png";
import Vera from "../../../../assets/icons/vera.png";
import "./TransferPage.css";

const TransferPage = () => {
  const [benificialOwner, setBenificialOwner] = useState(
    "086ae5ecce91c890a842931e509456c7328e5a23fa",
  );
  const [benificialCopyStatus, setBenificialCopyStatus] = useState(false);
  const [tranferCopyStatus, setTranferCopyStatus] = useState(false);

  const [transferDetails, setTransferDetails] = useState(
    `BUCKMINT-10-137-0xa208ee6'89alcb9efadeBd2d6a306e80f7a8e8d6495bd6ta3358ddb4b6a45103-1581`,
  );
  return (
    <div className="transfer_page">
      <div className="transfer_page_header">
        <h1>Retire Credit with</h1>
        <img src={Vera} alt="" />
      </div>
      <div className="transfer_box">
        <div>
          <img src={One} alt="" className="info_image" />
          <h1 className="transfer_box_heading"> Transfer</h1>
        </div>
        <div className="transfer_box_info">
          <h1>
            To transfer your Verra VCS/VCU carbon credits you first need to
            transfer them. Head over to{" "}
            <a
              href="https://registry.verra.org/
"
            >
              registry.verra.org
            </a>{" "}
            to retire your carbon credits, If you need help with your transfer,
            contact your partner or talk with us.
          </h1>
          <h1>
            <span>Important:</span> When transfering your credits make sure that
            the following information are being added to your transfer
          </h1>
        </div>
        <div className="transfer_page_details">
          <div className="transfer_page_details_items">
            <div className="transfer_page_details_items_head">
              <div className="transfer_page_details_items_header">
                Beneficial Owner
              </div>
              <div>
                {benificialCopyStatus && (
                  <span className="transfer_page_details_items_copied">
                    Copied!
                  </span>
                )}
              </div>
            </div>
            <div className="transfer_owner_address_container">
              <div className="transfer_owner_address">
                <p>{benificialOwner}</p>
              </div>
              <img
                onClick={() => {
                  navigator.clipboard.writeText(benificialOwner);
                  setBenificialCopyStatus(true);
                }}
                className="copy-icon"
                src={Copy}
                alt=""
              />
            </div>
          </div>
          <div className="transfer_page_details_items">
            <div className="transfer_page_details_items_header">
              Transfer Reason
            </div>
            <div className="transfer_owner_address">
              <p>Please select “Other” in the registry's dropdown.</p>
            </div>
          </div>
          <div className="transfer_page_details_items">
            <div className="transfer_page_details_items_head">
              <div className="transfer_page_details_items_header">
                Transfer Details
              </div>
              <div>
                {tranferCopyStatus && (
                  <span className="transfer_page_details_items_copied">
                    Copied!
                  </span>
                )}
              </div>
            </div>
            <h1></h1>
            <div className="transfer_owner_address_container">
              <div className="transfer_owner_address transfer_details_extra">
                <p>{transferDetails}</p>
              </div>
              <img
                onClick={() => {
                  navigator.clipboard.writeText(transferDetails);
                  setTranferCopyStatus(true);
                }}
                className="copy-icon"
                src={Copy}
                alt=""
              />
            </div>
          </div>
          <div className="transfer_page_additional_checkboxes">
            <h1>Additional Checkboxes</h1>
            <div>
              <p>
                Please select both of the following checkboxes in the Verra Ul:
              </p>
              <ul>
                <li>
                  Make Account Name, Beneficial Owner, and Transfer Reason
                  Public
                </li>
                <li> Make Transfer Reason Details Public </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="transfer_box">
        <div>
          <img src={Two} className="info_image" alt="" />
          <h1 className="transfer_box_last_heading">
            After your successful transfer, you will receive a unique serial
            number from the registry provider via e-mail which contains the
            information of your transferred carbon credit, It should have the
            following format:
            "0001-000001-000100-VCS-VCU-003-VER-US-0003-01012020-31122020-1". Go
            here for more information
          </h1>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default TransferPage;
