import React from "react";
import "./SubmitPage.css";
import Bco2 from "../../../../assets/icons/BCO2.png";
import { Project } from "../../../../config/projects";

interface SubmitPageProps {
  token: number;
  vintage: string;
  project: Project;
}

const SubmitPage = ({ token, project, vintage }: SubmitPageProps) => {
  return (
    <section className="submit_page">
      <h1 className="submit_page_heading">Submit Credit for Bridging</h1>
      <div className="submit_page_details">
        <img src={Bco2} alt="" />
        <div className="submit_page_numbers">
          <div>
            <h1>Number of Credits</h1>
            <p>{token}</p>
          </div>
          <div>
            <h1>Location</h1>
            <p>{project?.country}</p>
          </div>
          <div>
            <h1>Project Name</h1>
            <p>{project?.projectName}</p>
          </div>
          <div>
            <h1>Vintage</h1>
            <p>{vintage}</p>
          </div>
          <div>
            <h1>Project ID</h1>
            <p>{project?.projectId}</p>
          </div>
          <div>
            <h1>Methodology </h1>
            <p>VM0015</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubmitPage;
