import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UtilsStore } from "../../types";

export interface Utils {
  utilsStore: UtilsStore;
}

const initialNotes: Utils = {
  utilsStore: {
    emptyBatchNft: null,
  },
};

const utilsSlice = createSlice({
  name: "utilsStore",
  initialState: initialNotes,
  reducers: {
    setEmptyBatchNft(state, { payload }: PayloadAction<number | null>) {
      state.utilsStore.emptyBatchNft = payload;
      return state;
    },
  },
});

export const { setEmptyBatchNft } = utilsSlice.actions;

export default utilsSlice.reducer;
