import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { spvUrl } from "../../config";
import axios from "axios";
import { Certificate, CreditAPIResponse } from "../../types";
import { setCertificate } from ".";
import { AppState } from "..";

export const useSetCertificate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCertifcates = async () => {
      const allCredits = (await axios
        .get(`${spvUrl}/credit/get-credits`)
        .then((res) => res.data)) as CreditAPIResponse;

      if (allCredits) {
        let certificates: Certificate = {};

        allCredits?.data?.map((el) => {
          certificates[el?.serialNumber] = {
            projectId: el?.projectId,
            projectName: el?.projectName,
          };
        });

        console.log("certificates", certificates);

        dispatch(setCertificate(certificates));
      }
    };

    fetchCertifcates();
  }, [dispatch]);
};

export const useCertificate = () => {
  return useSelector((state: AppState) => state.certificate);
};
