import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "..";
import { getBCO2Tokens, getBuckmintProtocolData } from "../../utils/graphql";
import { useAppChainId } from "../application/hook";
import { setTotalBPT, setTotalBridged, setTotalRetired } from ".";
import { unitFormatter } from "../../utils";
import {
  useBaseCarbonTonneContract,
  useCarbonOffsetFactoryContract,
} from "../../hooks/useContract";
import { BCO2Tokens } from "../../graphql/types";
import { getMulticallBCO2Supply } from "../../utils/multicall/bco2Tokens";

// export const useSetProtocolData = () => {
//   const dispatch = useDispatch<AppDispatch>();
//   const appChainId = useAppChainId();
//   useEffect(() => {
//     if (appChainId !== 80001) return;

//     const fetchTotalRetirement = async () => {
//       const protocolData = await getBuckmintProtocolData(appChainId).then(
//         (value) => value
//       );

//       if (protocolData) {
//         dispatch(
//           setTotalBridged({
//             totalBridged: unitFormatter(protocolData?.totalBridged),
//           })
//         );
//         dispatch(
//           setTotalRetired({
//             totalRetired: unitFormatter(protocolData?.totalRetired),
//           })
//         );
//       }
//     };

//     fetchTotalRetirement();
//   }, [dispatch, appChainId]);
// };

export const useSetProtocolData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appChainId = useAppChainId();
  useEffect(() => {
    if (appChainId !== 80001) return;

    const fetchTotalRetirement = async () => {
      const bco2Tokens = (await getBCO2Tokens(appChainId).then(
        (value) => value,
      )) as BCO2Tokens[];

      const tokenAddresses: string[] = [];
      for (let i = 0; i < bco2Tokens.length; i++) {
        tokenAddresses.push(bco2Tokens?.[i]?.token);
      }

      const bco2Supply = await getMulticallBCO2Supply(
        appChainId,
        tokenAddresses,
      );

      let totalBCO2: number = 0;

      Object.values(bco2Supply).map((el) => {
        totalBCO2 += unitFormatter(el);
      });

      if (totalBCO2) {
        dispatch(
          setTotalBridged({
            totalBridged: totalBCO2,
          }),
        );
      }
    };

    fetchTotalRetirement();
  }, [dispatch, appChainId]);
};

export const useSetTotalBPT = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appChainId = useAppChainId();

  const bptContract = useBaseCarbonTonneContract();

  useEffect(() => {
    if (appChainId !== 80001) return;
    const fetchBptTotalSupply = async () => {
      const bptTotalSupply = await bptContract?.totalSupply();

      dispatch(setTotalBPT({ totalBPT: unitFormatter(bptTotalSupply) }));
    };

    fetchBptTotalSupply();
  }, [appChainId, dispatch, bptContract]);
};

export const useSetTotalRetired = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appChainId = useAppChainId();

  const factoryContract = useCarbonOffsetFactoryContract();

  useEffect(() => {
    if (appChainId !== 80001) return;
    const fetchBptTotalSupply = async () => {
      try {
        const totalRetired = await factoryContract.totalRetired();

        dispatch(setTotalRetired({ totalRetired: unitFormatter(totalRetired) }));
      } catch (err) {
        console.log("useSetTotalRetired Failed", err)
      }
      
    };

    fetchBptTotalSupply();
  }, [appChainId, dispatch, factoryContract]);
};

export const useProtocolData = () => {
  return useSelector((state: AppState) => state.protocol.protocolData);
};
