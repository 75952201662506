export const carbonOffsetBatcheAddress: { [key in number]: string } = {
  80001: "0xA8e3C3346c9227C67994C9bB7F6F07f573c836c9",
};

export const carbonProjects: { [key in number]: string } = {
  80001: "0xa6e46CF0d23B4959e83590A8315abBAA32A03Ee7",
};

export const baseCarbonTonne: { [key in number]: string } = {
  80001: "0x4859237387D29DcF0728547D04A86c2440b766e3",
};

export const carbonOffsetFactory: { [key in number]: string } = {
  80001: "0xa8504969654994639036A06B90Fc284a8bfD9C67",
};
