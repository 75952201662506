import { useCallback, useState } from "react";
import { getCarbonOffsetBatchesInterface } from "../utils/contract/Interface";
import { useWeb3React } from "@web3-react/core";
import { getCarbonOffsetBatchesEncodedCallData } from "../utils/contract/endcode";
import { carbonOffsetBatcheAddress } from "../config/contracts";
import { gasPrice } from "../utils";
import { useSetEmptyBatchNft } from "../store/utils/hook";
export enum INITIATE_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

interface InitiateBridgingResponse {
  initiateStatus: INITIATE_STATUS;
  newNFTId: number;
  triggerEmptyBatchMint: () => Promise<boolean | undefined>;
  transactionHash: string;
}

export const useMintEmptyBatches = (): InitiateBridgingResponse => {
  const [initiateStatus, setInitiateStatus] = useState<INITIATE_STATUS>();
  const [transactionHash, setTransactionHash] = useState<string>();
  const [newNFTId, setNewNFTId] = useState<number>();
  const iface = getCarbonOffsetBatchesInterface();
  const { library, account, chainId } = useWeb3React();
  const carbonOffsetBatchAddress = carbonOffsetBatcheAddress[Number(chainId)];
  const setEmptyBatchNft = useSetEmptyBatchNft();

  const triggerEmptyBatchMint = useCallback(async () => {
    if (!iface || !account || !carbonOffsetBatchAddress) return;
    try {
      setInitiateStatus(INITIATE_STATUS.LOADING);

      const callData = getCarbonOffsetBatchesEncodedCallData(
        iface,
        "mintEmptyBatch",
        [account],
      );

      // estimate gas on
      let gasLimit = await library.getSigner().estimateGas({
        from: account,
        to: carbonOffsetBatchAddress,
        data: callData,
        value: 0,
      });

      // gas price
      let gas_price = await gasPrice(library);

      const mint = await library.getSigner().sendTransaction({
        from: account,
        to: carbonOffsetBatchAddress,
        data: callData,
        gasLimit,
        gasPrice: gas_price,
        value: 0,
      });

      const tx = await mint.wait(2);
      setInitiateStatus(INITIATE_STATUS.COMPLETE);
      setTransactionHash(tx?.transactionHash);
      setNewNFTId(Number(tx?.logs?.[0].topics?.[3]));
      setEmptyBatchNft(Number(tx?.logs?.[0].topics?.[3]));
      return true;
    } catch (err) {
      setInitiateStatus(INITIATE_STATUS.ERROR);
      if (err instanceof Error) {
        console.log("useMintEmptyBatches failed", err?.message);
      }
      return false;
    }
  }, [account, iface, carbonOffsetBatchAddress, library, setEmptyBatchNft]);

  return {
    /** @ts-ignore */
    initiateStatus,
    /** @ts-ignore */
    transactionHash,
    /** @ts-ignore */
    newNFTId,
    triggerEmptyBatchMint,
  };
};
