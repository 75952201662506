import { AbstractConnector } from "@web3-react/abstract-connector";
import { injected } from "../connectors";
import { SUPPORTED_CHAIN_IDS } from "./chains";

// web3 wallets
export interface Web3Wallet {
  [wallet: string]: {
    /** web3 wallet name */
    walletName: string;
    /** web3 wallet icon */
    walletIcon: string;
    /** provider API */
    provider: AbstractConnector;
    /** supported networks */
    supportedNetworks: number[];
    /** external URL */
    externalUrl?: string;
    /** tell you about provider mobile support */
    isMobileSupported?: boolean;
  };
}

const wallets: Web3Wallet = {
  INJECTED: {
    walletName: "Injected",
    walletIcon: "",
    provider: injected,
    supportedNetworks: SUPPORTED_CHAIN_IDS,
  },
  // METAMASK: {
  //   walletName: "Metamask",
  //   walletIcon: "/images/wallets/metamask.png",
  //   provider: injected,
  //   supportedNetworks: supportedChainIds,
  // },
};

export default wallets;
