export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Status: RetirementStatus;
};

/**
 * RetirementStatus
 */
export enum RetirementStatus {
  Pending = "Pending",
  Rejected = "Rejected",
  Approved = "Approved",
  Initialized = "Initialized",
  Closed = "Closed",
}

/**
 * Retirement stores all the retired certificate
 */
export type Retirement = {
  __typename?: "Retirement";
  // retire transaction hash
  id: Scalars["ID"];
  // user address for retirement
  userAddress: Scalars["String"];
  // retire amount
  amount: Scalars["String"];
  // retirement event id
  retirementEventId: Scalars["String"];
};

/**
 * Fee paid
 */
export type FeePaid = {
  __typename?: "FeePaid";
  // retire transaction hash
  id: Scalars["ID"];
  // from erc721 recieved
  from: Scalars["String"];
  // fee amount
  feeAmount: Scalars["String"];
};

/**
 * Fee burnt
 */
export type FeeBurnt = {
  __typename?: "FeeBurnt";
  // retire transaction hash
  id: Scalars["ID"];
  // from erc721 recieved
  from: Scalars["String"];
  // fee burn amount
  feeBurnAmount: Scalars["String"];
};

/**
 * Deploy new BCO2
 */
export type BCO2Tokens = {
  __typename?: "TokenCreate";
  // new deployed token address
  id: Scalars["ID"];
  // project vintage token Id
  projectVintageTokenId: Scalars["Int"];
  // newly created bc02 address
  token: Scalars["String"];
};

/**
 * added account to the allowlist
 */
export type AllowList = {
  __typename?: "AllowList";
  // transaction hash
  id: Scalars["ID"];
  // user address
  user: Scalars["String"];
  // timestamp
  timestamp: Scalars["Int"];
};

/**
 * empty batch mint
 */
export type Batch = {
  __typename?: "BatchMint";
  // batch nft id
  id: Scalars["ID"];
  // nft minting user address
  user: Scalars["String"];
  // verra serial number
  serialNumber: Scalars["String"];
  // carbon credit qantity
  quantity: Scalars["Int"];
  // project vintage token id
  projectVintageTokenId: Scalars["Int"];
  // batch comments
  comments: Comments;
  // retirement status
  status: Scalars["Status"];
};

/**
 * Batch Comments
 */
export type Comments = {
  __typename?: "BatchComments";
  // batch nft id
  id: Scalars["ID"];
  // comment id
  commentId: Scalars["String"];
  // comment sender
  sender: Scalars["String"];
  // comment text
  comment: Scalars["String"];
};

/**
 * all projects
 */
export type MintedProject = {
  __typename?: "MintedProject";
  // transcation hash
  id: Scalars["ID"];
  // project user address
  to: Scalars["String"];
  // project token id
  projectId: Scalars["Int"];
  // tokenId
  tokenId: Scalars["Int"];
};

/**
 * All Project Vintage
 */
export type ProjectVintageMint = {
  __typename?: "ProjectVintageMint";
  // transcation hash
  id: Scalars["ID"];
  // user address
  receiver: Scalars["String"];
  // vintage nft id
  tokenId: Scalars["Int"];
  // project token id
  projectTokenId: Scalars["Int"];
  // vintage start time
  startTime: Scalars["Int"];
};

/**
 * all certificate
 */
export type MintCertificate = {
  __typename?: "MintCertificate";
  // transcation hash
  id: Scalars["ID"];
  // certificate nft Id
  tokenId: Scalars["Int"];
};

/**
 * buckmint protocol data
 */
export type BuckmintProtocolData = {
  _typename?: "BuckmintProtocolData";
  // total bridged
  totalBridged: Scalars["String"];
  // total retired
  totalRetired: Scalars["String"];
};

/**
 * Serial number and status
 */
export type SerialStatus = {
  _typename?: "Serials";
  // serial number
  serialNumber: Scalars["String"];
  // serial number status
  status: Scalars["String"];
};

/**
 * Order type which consist order details
 */
export type Order = {
  _typename?: "Orders";
  // id
  id: Scalars["String"];
  // order id
  orderId: Scalars["String"];
  // qunatity listed
  quantity: Scalars["String"];
  // seller address
  sellerAddress: Scalars["String"];
  // Token price
  price: Scalars["String"];
  // token address
  tokenAddress: Scalars["String"];
};

/**
 * Token type
 */
export type AllowedToken = {
  _typename?: "Orders";
  // token address id
  id: Scalars["String"];
  // is token allowed
  isAllowed: Scalars["Boolean"];
};
