import { ICall, IResponse } from "@makerdao/multicall";
import { multicall } from ".";

export const createMulticallForBalance = (
  chainId: number,
  target: string,
  args: string[],
): ICall => {
  return {
    target,
    call: ["balanceOf(address)(uint256)", ...args],
    returns: [[`${target?.toLowerCase()}`, (val) => val]],
  };
};

export const createMulticallForTotalSupply = (
  chainId: number,
  target: string,
): ICall => {
  return {
    target,
    call: ["totalSupply()(uint256)"],
    returns: [[`${target?.toLowerCase()}`, (val) => val]],
  };
};

export const createCallsForBalance = (
  chainId: number,
  account: string,
  tokenAddresses: string[],
) => {
  let calls: ICall[] = [];
  for (let i = 0; i < tokenAddresses.length; i++) {
    calls.push(
      createMulticallForBalance(chainId, tokenAddresses[i], [account]),
    );
  }
  return calls;
};

export const createCallsForTotalSupply = (
  chainId: number,
  tokenAddresses: string[],
) => {
  let calls: ICall[] = [];
  for (let i = 0; i < tokenAddresses.length; i++) {
    calls.push(createMulticallForTotalSupply(chainId, tokenAddresses[i]));
  }
  return calls;
};

export const getMulticallBalance = async (
  chainId: number,
  account: string,
  tokenAddresses: string[],
): Promise<{
  [key: string]: string;
} | null> => {
  const calls = createCallsForBalance(
    chainId,
    account,
    tokenAddresses,
  ) as ICall[];
  const balanceMulticall: IResponse = await multicall(chainId, calls);

  // return result results
  return balanceMulticall.results.original;
};

export const getMulticallBCO2Supply = async (
  chainId: number,
  tokenAddresses: string[],
): Promise<{
  [key: string]: string;
} | null> => {
  const calls = createCallsForTotalSupply(chainId, tokenAddresses) as ICall[];
  const totalSupplyMulticall: IResponse = await multicall(chainId, calls);

  // return result results
  return totalSupplyMulticall.results.original;
};
