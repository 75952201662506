export interface Project {
  projectName: string;
  country: string;
  projectId: string;
}

export const projects: Project[] = [
  {
    projectName: "Agrocortex REDD PROJECT",
    country: "Brazil",
    projectId: "VCS-1686",
  },
  {
    projectName: "FUJIAN YONG’AN IMPROVED FOREST MANAGEMENT PROJECT",
    country: "China",
    projectId: "VCS-1577",
  },
  {
    projectName: "Defensores del Chaco",
    country: "Paraguay",
    projectId: "VCS-168",
  },
  {
    projectName: "Kayapo Project",
    country: "Brazil",
    projectId: "VCS-162",
  },
  {
    projectName: "Million Trees Project",
    country: "Bhutan",
    projectId: "VCS-686",
  },
  {
    projectName: "VCS-612",
    country: "Kenya",
    projectId: "VCS-612",
  },
];

export interface ProjectData {
  [projectId: number]: {
    projectName: string;
    projectDate: string;
  };
}

export const projectsData: ProjectData = {
  1: {
    projectName: "India Sundarbans Mangrove Restoration",
    projectDate: "01-04-2018",
  },
  2: {
    projectName:
      "Biomass/Biogas based heat and power generation at Everest Starch",
    projectDate: "01-01-2018",
  },
  3: {
    projectName:
      "Bundled Wind Power Project in Tamil Nadu managed by Enercon India Limited-I",
    projectDate: "08-12-2018",
  },
  4: {
    projectName:
      "MRF wind power project in Tamilnadu managed by Enercon India Limited",
    projectDate: "11-12-2018",
  },
  5: {
    projectName:
      "44 MW Bundled Wind Power Project in Maharashtra managed by Enercon India Limited.",
    projectDate: "01-11-2018",
  },
  6: {
    projectName: "150 MW Solar Project in Karnataka by Avaada Solar",
    projectDate: "2012-2018",
  },
  7: {
    projectName: "300MW Hydropower project by JHPL",
    projectDate: "01-01-2018",
  },
  8: {
    projectName:
      "Renewable Power Project by Axis Wind Farms (MPR Dam) Private Limited",
    projectDate: "01-01-2018",
  },
  9: {
    projectName: "6 MW Wind Power Project in Maharashtra by PTC India",
    projectDate: "01-01-2018",
  },
  10: {
    projectName: "MRMPL Wind Power Project",
    projectDate: "01-01-2018",
  },
  11: {
    projectName:
      "WIND POWER PROJECT BY M/S DHARIWAL INDUSTRIES LTD. AND M/S R. M. DHARIWAL (HUF)",
    projectDate: "01-01-2018",
  },
  12: {
    projectName: "SONAWADE SMALL HYDRO POWER PROJECT",
    projectDate: "01-01-2018",
  },
  13: {
    projectName:
      "Waste Heat Recovery Power Project at JK Cement Works (Unit of JK Cement Limited), Nimbahera, Chittorgarh, Rajasthan",
    projectDate: "01-01-2018",
  },
  14: {
    projectName: "Vaspet-II and Vaspet-III Wind Power Project, Maharashtra",
    projectDate: "01-01-2018",
  },
  15: {
    projectName:
      "Solar Power Plant of 5 MW in Rajasthan by Essel Mining and Refex Refrigerants Limited",
    projectDate: "01-01-2018",
  },
  16: {
    projectName:
      "Wind Power Project by Rajasthan Gum Private Limited (EKI.CDM.July-11-01)",
    projectDate: "01-01-2018",
  },
  17: {
    projectName:
      "AAC Block Project By Aerocon Buildwell Pvt. Ltd. (EKIESL- June 2016-02)",
    projectDate: "01-01-2018",
  },
  18: {
    projectName: "Wind power project in Tirunelveli Tamilnadu",
    projectDate: "01-01-2018",
  },
  19: {
    projectName:
      "Bundled wind power project in Harshnath managed by Enercon (India) Limited",
    projectDate: "01-01-2018",
  },
  20: {
    projectName: "Kishanganga Hydroelectric Project",
    projectDate: "01-01-2018",
  },
  21: {
    projectName: "6.5 MW cogeneration project in Akbarpur, Punjab",
    projectDate: "01-01-2018",
  },
  22: {
    projectName:
      "Solar Power Plant of 15MW in Gujarat by Palace Solar Energy Private Limited",
    projectDate: "01-01-2018",
  },
  23: {
    projectName:
      "24.8 MW Wind power project by Belgaum Wind Farms Private Ltd. in Gadag, Karnataka",
    projectDate: "01-01-2018",
  },
  24: {
    projectName: "Veer (NLBC) Small Hydro Power Project",
    projectDate: "01-01-2018",
  },
  25: {
    projectName: "Suzlon 8.40 MW Wind Power Project",
    projectDate: "01-01-2018",
  },
  26: {
    projectName: "Renewable Solar Power Project By Rishabh Renergy",
    projectDate: "01-01-2018",
  },
  27: {
    projectName:
      "Bundled Solar Power Project by Solararise India Projects PVT. LTD.",
    projectDate: "01-01-2018",
  },
  28: {
    projectName:
      "Renewable Solar Power Project by ReNew Solar Power Private Limited",
    projectDate: "01-01-2018",
  },
  29: {
    projectName: "Energising Indian homes by Solar rooftop projects",
    projectDate: "01-01-2018",
  },
  30: {
    projectName:
      "Grid Connected Wind Power Project by M/s Giriraj Enterprises in Madhya Pradesh",
    projectDate: "01-01-2018",
  },
  31: {
    projectName:
      "7 MW Bundled Hydro power project at Himachal Pradesh of Raajratna Energy Holdings Pvt. Ltd",
    projectDate: "01-01-2018",
  },
  32: {
    projectName: "199.70 MW Wind Project in Maharashtra by BWDPL",
    projectDate: "01-01-201",
  },
  33: {
    projectName:
      "1.6 MW Bundled Rice Husk Based Cogeneration Plant by M/s Milk food Limited (MFL) in Patiala (Punjab) & Moradabad (U.P) Districts",
    projectDate: "01-01-201",
  },
  34: {
    projectName: "Tuppadahalli Wind Energy Project",
    projectDate: "01-01-201",
  },
  35: {
    projectName:
      "Grid connected 25 MW PV solar power project at Charanka in Gujarat",
    projectDate: "01-01-201",
  },
  36: {
    projectName: "Allain Duhangan Hydroelectric Project (ADHP)",
    projectDate: "01-01-201",
  },
  37: {
    projectName: "Sinner Wind Power Project in Maharashtra",
    projectDate: "01-07-201",
  },
  38: {
    projectName: "10 MW wind power project by Powerica at BDS - Gujarat",
    projectDate: "01-10-201",
  },
  39: {
    projectName: "Vajrakarur Wind Power Project in Andhra Pradesh",
    projectDate: "01-01-201",
  },
  40: {
    projectName: "Wind power project in Madhya Pradesh, India",
    projectDate: "01-01-201",
  },
  41: {
    projectName: "Wind power project in Rajasthan, India",
    projectDate: "01-07-201",
  },
  42: {
    projectName: "Lalpur wind farm in Gujarat",
    projectDate: "01-01-201",
  },
  43: {
    projectName:
      "Installation of wind power project in Rajasthan and Tamil Nadu",
    projectDate: "01-01-201",
  },
  44: {
    projectName: "80 MW SOLAR BY FERMI SOLAR FARMS PVT LTD - CHALISGAON.",
    projectDate: "01-11-201",
  },
  45: {
    projectName: "100 MW SOLAR PROJECT IN BHADLA IN RAJASTHAN.",
    projectDate: "01-11-201",
  },
  46: {
    projectName:
      "Wind Power Project by Green Infra Wind Power Generation Limited",
    projectDate: "01-01-201",
  },
  47: {
    projectName: "Grid Connected Wind Energy Project in Tamil Nadu",
    projectDate: "10-09-201",
  },
  48: {
    projectName: "Bundled Wind Power Project by Mytrah Group",
    projectDate: "01-01-201",
  },
  49: {
    projectName:
      "Hydroelectric Project in Kinnaur District in Himachal Pradesh",
    projectDate: "01-01-201",
  },
  50: {
    projectName: "Green Energy Project at Kutch by Powerica Limited",
    projectDate: "01-10-201",
  },
  51: {
    projectName: "Wind Power Project at Theni by Powerica Limited",
    projectDate: "11-09-201",
  },
  52: {
    projectName: "Wind Power Project at Bhachau by Powerica Limited",
    projectDate: "01-10-201",
  },
  53: {
    projectName: "Green Energy Project at Gujarat by Powerica Limited",
    projectDate: "01-10-201",
  },
  54: {
    projectName: "4 MW Biomass based cogeneration plant by Godrej Agrovet Ltd.",
    projectDate: "01-10-201",
  },
  55: {
    projectName: "Sustainable City Projects at India’s Cleanest City- Indore",
    projectDate: "01-01-201",
  },
  56: {
    projectName:
      "Grid connected bundled wind power project in Gujarat managed by Enercon (India) Limited",
    projectDate: "01-02-201",
  },
  57: {
    projectName:
      "150 MW grid connected Wind Power based electricity generation project in Gujarat, India.",
    projectDate: "01-01-201",
  },
  58: {
    projectName:
      "Wind Grouped project by Hero Future Energies Private Limited (EKIESL-VCS-Aug-16-03)",
    projectDate: "01-01-201",
  },
  59: {
    projectName: "Bundled Solar Photovoltaic Project by ACME",
    projectDate: "01-01-201",
  },
  60: {
    projectName:
      "Bundled Solar Power Project by Mahindra Susten Private Limited",
    projectDate: "24-05-201",
  },
  61: {
    projectName:
      "Wind Power Project in Tamil Nadu by Green Infra Renewable Energy Limited",
    projectDate: "08-10-201",
  },
  62: {
    projectName:
      "MRF wind power project in Tamilnadu managed by Enercon India Limited",
    projectDate: "11-03-201",
  },
  63: {
    projectName:
      "21.8 MW Wind Power Project at Jangi Vandhiya, Gujarat by Powerica Limited",
    projectDate: "01-10-201",
  },
  64: {
    projectName:
      "Wind Energy Project at Devbhoomi Dwaraka,Gujarat by Powerica Limited",
    projectDate: "01-10-201",
  },
  65: {
    projectName: "Wind power project at Gujarat by Powerica Limited",
    projectDate: "01-10-201",
  },
  66: {
    projectName: "46 MW Mangoli Wind Power Project in Karnataka",
    projectDate: "01-01-201",
  },
  67: {
    projectName: "216 MWac Kamuthi Solar Power Project",
    projectDate: "28-03-201",
  },
  68: {
    projectName:
      "Solar PV Power Project by Prayatna Developers Pvt. Ltd. at Bhatinda, Punjab",
    projectDate: "01-01-201",
  },
  69: {
    projectName: "72 MWac Ramnad Renewable Energy Project",
    projectDate: "28-06-201",
  },
  70: {
    projectName: "Selco Clean Energy Products Grouped Project",
    projectDate: "01-01-201",
  },
  71: {
    projectName:
      "Renewable Power Project by Devarahipparigi Wind Power Private Limited",
    projectDate: "01-08-201",
  },
  72: {
    projectName:
      "4.2 MW renewable energy based power generation project in Andhra Pradesh by Hi- Tech",
    projectDate: "01-01-201",
  },
  73: {
    projectName:
      "Biomass based cogeneration plant at Godrej Agrovet Ltd. Chintampalli",
    projectDate: "01-01-201",
  },
  74: {
    projectName: "3.5 MW Small Hydro Project In Himachal Pradesh",
    projectDate: "23-02-201",
  },
  75: {
    projectName:
      "Renewable Solar Power Project by Mahindra Renewables Private Limited",
    projectDate: "06-07-201",
  },
  76: {
    projectName: "Renewable Solar Power Project by Shapoorji Pallonji",
    projectDate: "18-04-201",
  },
  77: {
    projectName:
      "Renewable Wind Power Project by Axis Wind Farms (Rayalaseema) Pvt. Ltd",
    projectDate: "02-03-201",
  },
  78: {
    projectName: "20 MWAC (22 MWDC) SKCIL SOLAR POWER PLANT PROJECT",
    projectDate: "01-04-201",
  },
  79: {
    projectName: "Renewable Energy Power Projects by Atria Power",
    projectDate: "25-01-201",
  },
  80: {
    projectName: "Grid Interactive Solar Photovoltaic Power Project in Gujarat",
    projectDate: "01-01-201",
  },
  81: {
    projectName: "Ghani Solar Renewable Power Project by Greenko Group",
    projectDate: "01-01-201",
  },
  82: {
    projectName: "Wind Project in Maharashtra, India by Kayathar and Jath",
    projectDate: "01-09-201",
  },
  83: {
    projectName: "Wind bundle project in Maharashtra by Sispara",
    projectDate: "01-01-201",
  },
  84: {
    projectName: "Satara Wind Power Project in Maharashtra, India",
    projectDate: "01-09-201",
  },
  85: {
    projectName: "Solar Energy Project(s) by SB Energy Private Limited",
    projectDate: "01-01-201",
  },
  86: {
    projectName: "Wind Power Project at Anthiyur, Tamil Nadu",
    projectDate: "01-01-201",
  },
  87: {
    projectName:
      "100 MW Bundled Wind Power Project in Anantapuram, Andhra Pradesh",
    projectDate: "22-03-201",
  },
  88: {
    projectName: "Bundled Wind Power project in Koppal Karnataka",
    projectDate: "22-03-201",
  },
  89: {
    projectName: "Renewable Solar Power Project by Adani Green Energy Limited",
    projectDate: "01-01-201",
  },
  90: {
    projectName: "Bundled wind energy generation projects in Gujarat, India",
    projectDate: "01-01-201",
  },
  91: {
    projectName:
      "44 MW Bundled Wind Power Project in Maharashtra managed by Enercon India Limited.",
    projectDate: "01-04-201",
  },
  92: {
    projectName:
      "Bundled Solar Power Project by Vector Green Energy Private Limited",
    projectDate: "01-01-201",
  },
  93: {
    projectName:
      "Renewable Power Project by SEI Kathiravan Power Private Limited",
    projectDate: "26-07-201",
  },
  94: {
    projectName:
      "Wind power project in Maharashtra, India - Andhra Lake Phase - II",
    projectDate: "01-01-201",
  },
  95: {
    projectName:
      "Solar PV power project by Roha Dyechem Pvt. Ltd. (EKIESL.CDM.Aug-11-02)",
    projectDate: "01-01-201",
  },
  96: {
    projectName:
      "Wind based power generation by Panama Wind Energy Private Limited in Maharashtra, India",
    projectDate: "01-01-201",
  },
  97: {
    projectName:
      "20MW Samal Grid-connected Hydroelectric Project in Orissa, India",
    projectDate: "01-01-201",
  },
  98: {
    projectName: "86 MW Hydro Project in Himachal Pradesh",
    projectDate: "01-01-201",
  },
  99: {
    projectName: "Wind based renewable energy project in Gujarat",
    projectDate: "01-01-201",
  },
  100: {
    projectName:
      "Bundled Solar Power Project by D.J. Malpani and Giriraj Enterprises",
    projectDate: "26-03-201",
  },
  101: {
    projectName: "Grid Connected Solar Energy Project",
    projectDate: "01-01-201",
  },
  102: {
    projectName:
      "Wind Based Power Generation by Mytrah Energy (India) Limited (EKIESL-VCS-January-16-01)",
    projectDate: "01-01-201",
  },
  103: {
    projectName:
      "VCS Grouped Project for Renewable Power Generation by Essel Mining & Industries Limited",
    projectDate: "01-01-201",
  },
  104: {
    projectName: "Solar Power Project by Mytrah Energy India Private Limited",
    projectDate: "01-01-201",
  },
  105: {
    projectName: "Bundled Wind Power Project by Giriraj Enterprises",
    projectDate: "01-01-201",
  },
  106: {
    projectName: "Chakala Wind Power Project in Maharashtra",
    projectDate: "01-01-201",
  },
  107: {
    projectName: "Solar Grouped project by ACME Group (EKIESL-VCS-Aug-16-01)",
    projectDate: "01-01-201",
  },
  108: {
    projectName: "Wind Power Project of CLP Wind Farms (India) Private Limited",
    projectDate: "01-01-201",
  },
};
