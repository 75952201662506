import { useEffect, useState } from "react";
import { useTokenContract } from "./useContract";
import { unitFormatter } from "../utils";
import { useWeb3React } from "@web3-react/core";

// to user balance
export const useBalance = (token: string): number => {
  const { account } = useWeb3React();
  let tokenContract = useTokenContract(token);
  let [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    if (tokenContract && account) {
      // fetch allowance only once
      const derivedAllowance = async () => {
        try {
          const balance = await tokenContract.balanceOf(account);
          setBalance(unitFormatter(balance));
        } catch (err) {
          console.log("useAllowance Failed", err);
        }
      };
      derivedAllowance();
    }
  }, [tokenContract, account, token]);

  return balance;
};
