import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

interface GraphUrl {
  [chainId: number]: string;
}

const endpoints: GraphUrl = {
  80001:
    "https://api.thegraph.com/subgraphs/name/dev-ramverma/buckmint-mumbai-testnet",
};

const marketplaceEndpoints: GraphUrl = {
  80001:
    "https://api.thegraph.com/subgraphs/name/ram-buckmint/buckmint-marketplace-testnet",
};

export const getBuckmintGraphqlClient = (
  chainId: number,
): ApolloClient<NormalizedCacheObject> | null => {
  const endpoint = endpoints[chainId];
  if (!endpoint) return null;
  return new ApolloClient({
    uri: endpoint,
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            ALL_BUCKMINT: {
              keyArgs: false,
              merge: (existing = [], incoming) => {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });
};

export const getBuckmintMarketplaceGraphqlClient = (
  chainId: number,
): ApolloClient<NormalizedCacheObject> | null => {
  const endpoint = marketplaceEndpoints[chainId];
  if (!endpoint) return null;
  return new ApolloClient({
    uri: endpoint,
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            ALL_BUCKMINT: {
              keyArgs: false,
              merge: (existing = [], incoming) => {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });
};
