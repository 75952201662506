import { polygonRpc } from "./rpc";

export const SUPPORTED_CHAIN_IDS: number[] = [80001];

// Polygon ChainId
export const POLYGON_CHAIN = 80001;

export const chainsConfig = {
  80001: [
    {
      chainId: "0x13881",
      chainName: "POLYGON Mumbai",
      nativeCurrency: {
        name: "MATIC TOKEN",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: [polygonRpc],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      iconUrls: ["../assets/icons/polygon.png"],
    },
  ],
};
