import { gql } from "@apollo/client";

export const batchMintedQuery = gql`
  query Batches($user: String!, $first: Int) {
    batches(where: { user: $user }, first: $first) {
      id
      projectVintageTokenId
      quantity
      serialNumber
      status
      user
      comments {
        id
        comment
        commentId
        sender
      }
    }
  }
`;

export const batchUpdateQuery = gql`
  query Batches($where: Batch_Update_filter) {
    batchUpdates(where: $where) {
      quantity
      id
      serialNumber
    }
  }
`;

export const batchStatusQuery = gql`
  query Batches($where: Batch_Status_filter) {
    batchStatuses(where: $where) {
      id
      status
    }
  }
`;

export const batchCommentsQuery = gql`
  query Batches($where: Batch_Status_filter) {
    batchComments(where: $where) {
      comment
      commentId
      id
      sender
    }
  }
`;

export const addToAllowListsQuery = gql`
  query Batches($where: Allow_List_filter) {
    addToAllowLists(where: $where) {
      comment
      commentId
      id
      sender
    }
  }
`;

export const projectMintedQuery = gql`
  query Projects {
    mintedProjects {
      id
      projectId
      to
      tokenId
    }
  }
`;

export const projectVintageMintsQuery = gql`
  query ProjectVintage {
    projectVintageMints(where: $where) {
      projectTokenId
      receiver
      startTime
      tokenId
    }
  }
`;

export const retirementsQuery = gql`
  query ProjectVintage($where: Retirement_filter) {
    retirements(where: $where) {
      amount
      id
      retirementEventId
      userAddress
    }
  }
`;

export const mintCertificatesQuery = gql`
  query Certificates($where: Certificate_filter) {
    mintCertificates(where: $where) {
      id
      tokenId
    }
  }
`;

export const bco2TokenQuery = gql`
  query Token {
    bco2Tokens {
      projectVintageTokenId
      token
      id
    }
  }
`;

export const buckmintProtocolDataQuery = gql`
  query ProtocolInfo {
    protcolDatas {
      id
      totalCarbonBridged
      totalRetired
    }
  }
`;

export const allSerialNumberQuery = gql`
  query Batch {
    batches {
      status
      serialNumber
    }
  }
`;

export const listedOrderQuery = gql`
  query Order {
    listedOrders {
      id
      orderId
      price
      quantity
      sellerAddress
      tokenAddress
    }
  }
`;

export const allowedTokenQuery = gql`
  query AllowedToken {
    allowedTokens {
      id
      isAllowed
    }
  }
`;
