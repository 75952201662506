import React from "react";
import Stella from "../../assets/icons/stella.png";
import "./styles.css";
import { generateRandomNumber } from "../../utils";
import { projects } from "../../config/projects";
// import { AUTO_REDEEM_STATUS, useAutoRedeem } from "../../hooks/useAutoRedeem";
import { RETIRE_STATUS, useRetire } from "../../hooks/useRetire";
import { ClipLoader } from "react-spinners";

interface RetirementModelProps {
  setOpenRetire: (retire: boolean) => void;
  balance: number;
  retireAmount: number;
  setRetireAmount: (retireAmount: number) => void;
}

const RetireModal = ({
  balance,
  retireAmount,
  setRetireAmount,
  setOpenRetire,
}: RetirementModelProps) => {
  const [randomNumber] = React.useState<number>(generateRandomNumber(6));

  const { retireStatus, triggerRetire } = useRetire();

  return (
    <div className="retire_modal_background">
      <div className="retire_modal_Container">
        {retireStatus === RETIRE_STATUS.COMPLETE ? (
          <>
            <div className="retire-head">Congratulations!!!</div>
            <div className="retire_modal_body">
              <div className="font-h3">
                You have retired {retireAmount} BPT tokens to offset{" "}
                {retireAmount} tCO2e.
              </div>
              <div className="retire-summary">
                <div className="font-h2">Summary</div>
                <div className="summary-table font-h4">
                  <div className="table-left">Project Name</div>
                  <div className="table-right">
                    {projects[randomNumber]?.projectName}
                  </div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">Vintage</div>
                  <div className="table-right">2019</div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">Total CO2e offset</div>
                  <div className="table-right">{retireAmount} tCO2e</div>
                </div>
              </div>
            </div>
            <div className="retire_modal_footer">
              <button
                className="retire_modal_footer_button"
                onClick={() => setOpenRetire(false)}
                id="cancelBtn"
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="retire-head">Offset Emissions </div>
            <div className="retire_modal_body">
              <div className="font-h2">Token Retirement</div>
              <div className="font-h3">
                Select the bundle token and amount you want to retire.
              </div>
              <div className="retire-section">
                <img
                  src={Stella}
                  width="54px"
                  height="54px"
                  alt="retire-logo"
                />
                <div className="retirecard">
                  <div className="font-h3">BPT</div>
                  <div className="font-h2">Buckmint Pool Token</div>
                </div>
                <div className="retirecard">
                  <div className="font-h3">Balance</div>
                  <div className="font-h2">{balance}</div>
                </div>
                <div className="retirecard">
                  <div className="font-h3">Retire to offset</div>
                  <div className="font-h2">
                    <input
                      value={retireAmount}
                      style={{ paddingLeft: "5px" }}
                      onChange={(e) => setRetireAmount(Number(e.target.value))}
                      name="retire-amount"
                      placeholder="Click to enter the quantity"
                    />
                  </div>
                </div>
              </div>
              <div className="retire-summary">
                <div className="font-h2">Summary</div>
                <div className="summary-table font-h4">
                  <div className="table-left">Project Name</div>
                  <div className="table-right">
                    {projects[randomNumber]?.projectName}
                  </div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">Vintage</div>
                  <div className="table-right">2018</div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">Total CO2e offset</div>
                  <div className="table-right">{retireAmount} tCO2e</div>
                </div>
              </div>
            </div>
            <div className="retire_modal_footer">
              <button
                className="retire_modal_footer_button"
                onClick={() => setOpenRetire(false)}
                id="cancelBtn"
              >
                Close
              </button>
              <button
                className="retire_modal_footer_button filled_button"
                id="retiresct"
                onClick={() => triggerRetire(retireAmount, 1)}
              >
                {" "}
                {retireStatus === RETIRE_STATUS.LOADING ? (
                  <>
                    <ClipLoader color="white" size={20} />
                    &nbsp; {`Retire to Offset`}
                  </>
                ) : (
                  `Retire to Offset`
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RetireModal;
