import { ICall, IResponse } from "@makerdao/multicall";
import { multicall } from ".";

export const createMulticallForBalance = (
  chainId: number,
  target: string,
  args: string[],
): ICall => {
  return {
    target,
    call: ["balanceOf(address)(uint256)", ...args],
    returns: [[`${target?.toLowerCase()}`, (val) => val]],
  };
};

export const getMulticallBalance = async (
  chainId: number,
  account: string,
  tokenAddress: string,
): Promise<{
  [key: string]: string;
} | null> => {
  const calls = createMulticallForBalance(chainId, tokenAddress, [
    account,
  ]) as ICall;
  const balanceMulticall: IResponse = await multicall(chainId, [calls]);

  // return result results
  return balanceMulticall.results.original;
};
