import TransactionBar2 from "../../components/BuckmintCo2Batches/BuckmintCo2Batches";
import TransactionBar3 from "../../components/CarbonBundleTokens/CarbonBundleTokens";
import Connect from "../../components/ConnectWallet/ConnectWallet";
import Refresh from "../../components/Refresh/Refresh";
import Testnet from "../../components/Testnet/Testnet";
import "./Bundles.css";

function Bundles() {
  return (
    <div className="pools_home home">
      <section className="pools_section1 section1">
        <Testnet />
        <Connect />
      </section>
      <section className="pools_section3 section3">
        <h1>My Bundle Assets</h1>
        <div>
          <Refresh />
        </div>
      </section>
      <section className="pools_section4 section4">
        <TransactionBar3 heading="Carbon Bundle Tokens" value={15} />
        <TransactionBar2 heading="Buckmint CO2 Tokens (BCO2)" value={15} />
      </section>
    </div>
  );
}

export default Bundles;
