import React from "react";
import { Link, useLocation } from "react-router-dom";
import bridge from "../../assets/icons/bridge1.png";
import buckmintLogo from "../../assets/icons/buckmint.png";
import Discord from "../../assets/icons/discord.png";
import Docs from "../../assets/icons/Docs.png";
import Document from "../../assets/icons/document.png";
import Pools from "../../assets/icons/pools.png";
import Marketplace from "../../assets/icons/marketplace.png";
import Legal from "../Legal/Legal";
import Privacy from "../Privacy/Privacy";
import "./Sidebar.css";

const Sidebar: React.FC = () => {
  const [openLegal, setOpenLegal] = React.useState<boolean>(false);
  const [openPrivacy, setOpenPrivacy] = React.useState<boolean>(false);
  const location = useLocation();

  function setLegalOnClick() {
    setOpenLegal(true);
  }

  return (
    <aside className="sidebar">
      <section className="upperSideBar">
        <Link to="/" className="link">
          <div id="logo">
            <img src={buckmintLogo} alt="" className="logoImage" />
          </div>
        </Link>
        <Link to="/" className="link">
          <div
            id="overview"
            className={
              location.pathname === "/" ? "green sidebar_item" : "sidebar_item"
            }
          >
            <img src={Docs} alt="" className="sidebar_icons" id="docs" />
            <h1
              className={
                location.pathname === "/"
                  ? "greenHeading sidebar_item_heading"
                  : "sidebar_item_heading"
              }
            >
              Overview
            </h1>
          </div>
        </Link>
        <Link to="/bridge" className="link" id="bridge">
          <div
            id="bridge"
            className={
              location.pathname === "/bridge"
                ? "green sidebar_item"
                : "sidebar_item"
            }
          >
            <img src={bridge} alt="" className="sidebar_icons" id="bridge" />
            <h1
              className={
                location.pathname === "/bridge"
                  ? "greenHeading sidebar_item_heading"
                  : "sidebar_item_heading"
              }
            >
              Bridge
            </h1>
          </div>
        </Link>
        <Link to="/bundle" className="link">
          <div
            id="pools"
            className={
              location.pathname === "/bundle"
                ? "green sidebar_item"
                : "sidebar_item"
            }
          >
            <img src={Pools} alt="" className="sidebar_icons" id="pools" />{" "}
            <h1
              className={
                location.pathname === "/bundle"
                  ? "greenHeading sidebar_item_heading"
                  : "sidebar_item_heading"
              }
            >
              Bundle
            </h1>
          </div>
        </Link>
        <Link to="/marketplace" className="link">
          <div
            id="marketplace"
            className={
              location.pathname === "/marketplace"
                ? "green sidebar_item"
                : "sidebar_item"
            }
          >
            <img
              src={Marketplace}
              alt=""
              className="sidebar_icons"
              id="marketplace"
            />{" "}
            <h1
              className={
                location.pathname === "/marketplace"
                  ? "greenHeading sidebar_item_heading"
                  : "sidebar_item_heading"
              }
            >
              Marketplace
            </h1>
          </div>
        </Link>
      </section>
      <section className="lowerSideBar">
        <a href="https://docs.buckmints.com/" target="_blank" rel="noreferrer">
          <div id="Docs" className="sidebar_item">
            <img src={Document} alt="" className="sidebar_icons" />
            <h1 className="sidebar_item_heading">Docs</h1>
          </div>
        </a>
        <a href="https://buckmints.com/" target="_blank" rel="noreferrer">
          <div id="Github" className="sidebar_item">
            <img src={Discord} alt="" className="sidebar_icons" />{" "}
            <h1 className="sidebar_item_heading">Discord</h1>
          </div>
        </a>
        <div>
          <div id="footerItem">
            <h2 onClick={() => setOpenLegal(true)} className="legal_privacy">
              Legal
            </h2>
            <h2 onClick={() => setOpenPrivacy(true)} className="legal_privacy">
              {" "}
              Privacy
            </h2>
          </div>
          {openLegal && <Legal closeModal={setOpenLegal} />}
          {openPrivacy && <Privacy closeModal={setOpenPrivacy} />}
        </div>
      </section>
    </aside>
  );
};

export default Sidebar;
