import { useWeb3React } from "@web3-react/core";
import React from "react";
import ArrowUp from "../../assets/icons/arrowDown.png";
import ArrowDown from "../../assets/icons/arrowUp.png";
import Switch from "../../assets/icons/switch.png";
import wallet from "../../assets/icons/wallet.png";
import { useDisconnect } from "../../hooks/useWallet";
import { miniWalletAddress } from "../../utils";
import "./ConnectWallet.css";
import ConnectWalletModal from "./ConnectWalletModal/ConnectWalletModal";

const ConnectWallet: React.FC = () => {
  const { active, account } = useWeb3React();
  const disconnect = useDisconnect();
  const [openConnectWallet, setConnectWallet] = React.useState<boolean>(false);
  const [Profile, setProfile] = React.useState<boolean>(false);
  const dropdownRefProfile = React.useRef<HTMLDivElement>(null);
  const handleDropDownFocusProfile = (state: boolean) => {
    setProfile(!state);
  };
  const handleClickOutsideDropdownProfile = (e: any) => {
    if (Profile && !dropdownRefProfile.current?.contains(e.target as Node)) {
      setProfile(false);
    }
  };
  window.addEventListener("click", handleClickOutsideDropdownProfile);

  const onHandleDisconnect = () => {
    disconnect();
  };

  return (
    <div className="connectWalletContainer">
      {openConnectWallet && (
        <ConnectWalletModal closeModal={() => setConnectWallet(false)} />
      )}
      {active && account ? (
        <div className="_connect_wallet">
          <div
            className="_connect_wallet_dropdown_container"
            ref={dropdownRefProfile}
          >
            <button onClick={(e) => handleDropDownFocusProfile(Profile)}>
              <h1>{miniWalletAddress(account)}</h1>
              {Profile ? (
                <img src={ArrowDown} alt="arrow-down" />
              ) : (
                <img src={ArrowUp} alt="arrow-up" />
              )}
            </button>
            {Profile && (
              <ul>
                <li>
                  <h1>Signed in as</h1>
                  <h3>{miniWalletAddress(account, 6)}</h3>
                </li>
                <li>
                  <h1>Balance</h1>
                  <h3>{0}</h3>
                </li>
                <li>
                  <h1>Network</h1>
                  <h3>{`Polygon`}</h3>
                </li>
                <li
                  onClick={() => {
                    onHandleDisconnect();
                    handleClickOutsideDropdownProfile(Profile);
                  }}
                >
                  <h1>Disconnect</h1>
                  <img src={Switch} alt="switch" />
                </li>
              </ul>
            )}
          </div>
        </div>
      ) : (
        <button
          className="connectWalletOpenModalBtn"
          onClick={() => setConnectWallet(true)}
        >
          <img src={wallet} alt="wallet" />
          <p>Connect Wallet</p>
        </button>
      )}
    </div>
  );
};

export default ConnectWallet;
