import { unitFormatter } from ".";
import { Order } from "../graphql/types";
import { AllowedTokenMetdata, OrdersMetaData } from "../store/marketplace";
import { BuckmintCo2Batch } from "../types";

export const filterMarketplaceOrder = (
  buckmintCO2Batch: BuckmintCo2Batch[],
  order: Order[],
): OrdersMetaData[] => {
  let bco2Tokens: {
    [tokenAddress: string]: { projectName: string; projectVintage: string };
  } = {};

  buckmintCO2Batch?.map((el) => {
    bco2Tokens[`${el?.tokenAddress?.toLowerCase()}`] = {
      projectName: el?.projectName,
      projectVintage: el?.projectDate,
    };
  });

  const orderMetadata: OrdersMetaData[] = order.map((el) => {
    return {
      id: el?.id,
      orderId: el?.orderId,
      quantity: unitFormatter(el?.quantity),
      sellerAddress: el?.sellerAddress?.toLowerCase(),
      tokenAddress: el?.tokenAddress?.toLowerCase(),
      price: unitFormatter(el?.price, 6),
      projectName: bco2Tokens?.[el?.tokenAddress?.toLowerCase()]?.projectName,
      projectVintage:
        bco2Tokens?.[el?.tokenAddress?.toLowerCase()]?.projectVintage,
    };
  });

  return orderMetadata;
};

export const formatAllowedToken = (
  buckmintCO2Batch: BuckmintCo2Batch[],
  allowedToken: string[],
): AllowedTokenMetdata[] => {
  let bco2Tokens: {
    [tokenAddress: string]: { balance: number; projectName: string };
  } = {};

  buckmintCO2Batch?.map((el) => {
    bco2Tokens[`${el?.tokenAddress?.toLowerCase()}`] = {
      balance: el?.balance1 > 0 ? el?.balance1 : 0,
      projectName: el?.projectName ? el?.projectName : "",
    };
  });

  const orderMetadata: AllowedTokenMetdata[] = allowedToken?.map((el) => {
    return {
      tokenAddress: el,
      balance: bco2Tokens?.[el?.toLowerCase()]?.balance,
      projectName: bco2Tokens?.[el?.toLowerCase()]?.projectName,
    };
  });

  return orderMetadata;
};
