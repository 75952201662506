import "./Legal.css";

function Legal({ closeModal }: { closeModal: Function }) {
  return (
    <div className="legal_modal_background">
      <div className="legal_modal_Container">
        <h1>LEGAL DISCLAIMER </h1>
        <div className="legal_modal_body">
          <p>
            By using the Carbon Bridge or the Carbon Pools, you accept that
            doing so is strictly at your own risk. We assume no responsibility
            or liability for any errors or omissions made during the deployment
            or use of the Carbon Bridge or the Carbon Pools or for any other
            errors or omissions contained on this site. The Carbon Bridge, the
            Carbon Pools and all other information contained on this site are
            provided on an "as is" basis without warranty of any kind, with no
            guarantees of completeness, accuracy, usefulness or timeliness.
            Carbon credits that have previously been issued under now phased out
            carbon credit programs may not be bridged. In no event will we be
            liable for any claim, damage or other liability including, without
            limitation, indirect or consequential loss or damage, or any loss or
            damage whatsoever arising from, out of, or in connection with, the
            use of the Carbon Bridge, Carbon Pools or this website.
          </p>
        </div>
        <div className="legal_modal_footer">
          <button onClick={() => closeModal(false)} id="cancelBtn">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default Legal;
