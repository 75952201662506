import React, { ReactElement } from "react";
import { IoIosArrowDown } from "react-icons/io";
import BCO2 from "../../../assets/icons/BCO2.png";
import { AllowedTokenMetdata } from "../../../store/marketplace";
import { BuckmintCo2Batch, BuckmintPoolToken } from "../../../types";
import {
  ApprovalResponse,
  ApprovalTransactionStatus,
} from "../../../hooks/useApprove";
import { SellResponse, SellTransactionStatus } from "../../../hooks/useSell";
import { ClipLoader } from "react-spinners";
import CustomTooltip from "../../../components/ToolTip/CustomTooltip";
import { useBuckmintPoolTokens } from "../../../store/tokens/hook";

interface SellSectionProps {
  setIsTokenModelOpen: () => void;
  selectedToken: AllowedTokenMetdata;
  handleList: () => void;
  selectedProject: BuckmintPoolToken;
  projectId: string;
  quantity: number;
  setQuantity: (quantity: number) => void;
  price: number;
  setPrice: (price: number) => void;
  bco2Response: ApprovalResponse;
  sellResponse: SellResponse;
  handleBco2Approve: () => void;
  tokenSelectError: boolean;
  sellQuantityError: boolean;
  sellPriceError: boolean;
}

const SellSection: React.FC<SellSectionProps> = ({
  setIsTokenModelOpen,
  selectedToken,
  handleList,
  selectedProject,
  projectId,
  quantity,
  setQuantity,
  price,
  setPrice,
  bco2Response,
  sellResponse,
  handleBco2Approve,
  tokenSelectError,
  sellQuantityError,
  sellPriceError,
}: SellSectionProps): ReactElement => {
  console.log("selectedToken", selectedToken);
  return (
    <>
      <div className="sell_container">
        <div
          className={!tokenSelectError ? "select_token" : "select_token_error"}
          onClick={() => setIsTokenModelOpen()}
        >
          {selectedToken ? (
            <p>{selectedToken?.tokenName?.slice(0, 30)}</p>
          ) : (
            <p>Select Token</p>
          )}
          <IoIosArrowDown />
        </div>
        {
          <div className="sell_token_desc">
            <div className="card_part">
              <h2 className="symbol"> BPT </h2>
              <img src={BCO2} alt="logo" />
            </div>
            {/* <div className="card_part">
              <p className="pId">
                Project Id <span>i</span>
              </p>
              <p className="pId">{projectId ? projectId : "-"}</p>
            </div> */}
            {/* <div className="card_part">
              <p className="id">
                Project Name <span>i</span>
              </p>
              <p className="id">
                <CustomTooltip text={selectedProject?.projectName}>
                  {
                    `${selectedProject?.projectName?.split(" ").slice(0, 3).join(" ")}......`
                  }
                </CustomTooltip>
              </p>
            </div> */}
            {/* <div className="card_part">
              <p className="vintage">
                Project Vintage <span>i</span>
              </p>
              <p className="vintage">{selectedProject?.projectDate}</p>
            </div> */}
            {/* <div className="card_part">
              <p className="price">
                Token Address <span>i</span>
              </p>
              <p className="price">{selectedToken?.tokenAddress}</p>
            </div> */}
            <hr />
            <div className="card_part">
              <p className="price">
                Avaialble balance <span>i</span>
              </p>
              <p className="price">{selectedToken?.balance}</p>
            </div>

            <div className="retire-section">
              <div className="retirecard">
                <div className="font-h3">Token to sell</div>
                <div className="font-h2">
                  <input
                    value={quantity}
                    style={{
                      paddingLeft: "5px",
                      border: sellQuantityError && "1px solid red",
                    }}
                    onChange={(e) => setQuantity(Number(e?.target.value))}
                    name="retire-amount"
                    placeholder="enter amount"
                  />
                </div>
              </div>
              <div className="retirecard">
                <div className="font-h3">Selling Price ($)</div>
                <div className="font-h2">
                  <input
                    value={price}
                    style={{
                      paddingLeft: "5px",
                      border: sellPriceError && "1px solid red",
                    }}
                    onChange={(e) => setPrice(Number(e?.target.value))}
                    name="retire-amount"
                    placeholder="enter selling price"
                  />
                </div>
              </div>
            </div>
            <div className="sell_token_btns">
              <button
                className="sell_btn approve btn"
                type="button"
                onClick={() => handleBco2Approve()}
              >
                {bco2Response.approvalTxStatus ===
                ApprovalTransactionStatus.APPROVAL_LOADING ? (
                  <>
                    <ClipLoader color="white" size={10} />
                    &nbsp; Approve
                  </>
                ) : (
                  "Approve"
                )}
              </button>
              <button
                className="sell_btn list btn"
                type="button"
                onClick={() => sellResponse.triggeredSell()}
              >
                {" "}
                {sellResponse.sellTxStatus ===
                SellTransactionStatus.SELL_LOADING ? (
                  <>
                    <ClipLoader color="white" size={10} />
                    &nbsp; sell
                  </>
                ) : (
                  "sell"
                )}
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default SellSection;
