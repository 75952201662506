import { ICall, IResponse } from "@makerdao/multicall";
import { multicall } from ".";

export const createMulticallForOwnerOf = (
  chainId: number,
  target: string,
  args: string[],
): ICall => {
  return {
    target,
    call: ["ownerOf(uint256)(address)", ...args],
    returns: [[`${parseInt(args[0])}`, (val) => val]],
  };
};

export const createCallsForOwnerOf = (
  chainId: number,
  target: string,
  tokenIds: string[],
) => {
  let calls: ICall[] = [];
  for (let i = 0; i < tokenIds.length; i++) {
    calls.push(createMulticallForOwnerOf(chainId, target, [tokenIds[i]]));
  }
  return calls;
};

export const getMulticallOwner = async (
  chainId: number,
  target: string,
  tokenIds: string[],
): Promise<{
  [key: string]: string;
} | null> => {
  const calls = createCallsForOwnerOf(chainId, target, tokenIds) as ICall[];
  console.log("calls", calls);
  const OwnerMulticall: IResponse = await multicall(chainId, calls);

  // return result results
  return OwnerMulticall.results.original;
};
