import React from "react";
import "./ConnectWalletModal.css";
import Coin from "../../../assets/icons/coin-.png";
import Meta from "../../../assets/icons/metamask.png";
import rightArrow from "../../../assets/icons/rightArrow.png";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import wallets from "../../../config/wallet";

interface ConnectWalletProps {
  closeModal: () => void;
}

function ConnectWalletModal({ closeModal }: ConnectWalletProps) {
  const [connector] = React.useState<AbstractConnector>(
    wallets["INJECTED"].provider,
  );
  const { activate } = useWeb3React();
  const activating = async () => {
    closeModal();
    return connector && activate(connector);
  };

  return (
    <div className="connectWalletModalBackground">
      <div className="connectWalletModalContainer">
        <div className="connectWalletModalTitleCloseBtn">
          <button onClick={() => closeModal()}>X</button>
        </div>
        <div className="modal_title">
          <h1>Connect wallet</h1>
          <p>Use an existing Web3 wallet to connect to Buckmint</p>
        </div>
        <div className="modal_body">
          <div className="wallets">
            <div className="wallet_items">
              {!(window as any)?.ethereum ? (
                <div>
                  <img src={Meta} alt="" className="wallet_logo" />
                  <h3>
                    <a
                      href="https://metamask.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Install Metamask Wallet
                    </a>
                  </h3>
                </div>
              ) : (
                <div onClick={() => activating()}>
                  <img src={Meta} alt="" className="wallet_logo" />
                  <h3>Connect Metamask</h3>
                </div>
              )}
              {/* <img src={rightArrow} alt="" /> */}
            </div>
            {/* <div className="wallet_items">
              <div>
                <img src={Coin} alt="" className="wallet_logo" />
                <h3>CoinBase Wallet Install</h3>
              </div>
              <img src={rightArrow} alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectWalletModal;
