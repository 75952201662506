import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import WalletManager from "./components/WalletManager";
import Bridge from "./Pages/Bridge/Bridge";
import Bundles from "./Pages/Bundles/Bundles";
import Overview from "./Pages/Overview/Overview";
import Marketplace from "./Pages/Marketplace/Marketplace";
import "react-toastify/dist/ReactToastify.css";
import store from "./store";
import { QueryResolvers } from "./components/QueryResolvers";
import { ToastContainer } from "react-toastify";

const App = () => {
  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => {
    return new Web3Provider(provider);
  };

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Web3ReactProvider getLibrary={getLibrary}>
          <WalletManager />
          <ToastContainer />
          <Routes>
            <Route
              path="/"
              element={
                <div className="app">
                  <div className="side">
                    <Sidebar />
                  </div>
                  <Overview />
                </div>
              }
            />
            <Route
              path="/bridge"
              element={
                <div className="app">
                  <div className="side">
                    <Sidebar />
                  </div>
                  <Bridge />
                </div>
              }
            />
            <Route
              path="/bundle"
              element={
                <div className="app">
                  <div className="side">
                    <Sidebar />
                  </div>
                  <Bundles />
                </div>
              }
            />
            <Route
              path="/marketplace"
              element={
                <div className="app">
                  <div className="side">
                    <Sidebar />
                  </div>
                  <Marketplace />
                </div>
              }
            />
          </Routes>
          <QueryResolvers />
        </Web3ReactProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
