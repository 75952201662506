import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import tokens from "./tokens";
import application from "./application";
import utilStore from "./utils";
import protocol from "./protocol";
import projects from "./projects";
import certificate from "./certificate";
import serialStatus from "./serialStatus";
import marketplace from "./marketplace";

const PERSISTED_KEYS: string[] = [
  "application",
  "tokens",
  "protocol",
  "projects",
  "certificate",
  "serialStatus",
  "marketplace",
];

const store = configureStore({
  reducer: {
    application,
    tokens,
    utilStore,
    protocol,
    projects,
    certificate,
    serialStatus,
    marketplace,
  },
  middleware: [save({ states: PERSISTED_KEYS, namespace: "testnet" })],
  preloadedState: load({
    states: PERSISTED_KEYS,
    namespace: "testnet",
  }),
  devTools: true,
});

// Whole App State Data Type
export type AppState = ReturnType<typeof store.getState>;

// App Dispatcher Data Type
export type AppDispatch = typeof store.dispatch;

// export the store
export default store;
