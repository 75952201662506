import React, { useCallback, useEffect } from "react";
import Bridge from "../../assets/icons/bridge.png";
import "./Initiate.css";
import InitiateBridgingModal from "./InitiateBridgingModal/InitiateBridgingModal";
import {
  INITIATE_STATUS,
  useMintEmptyBatches,
} from "../../hooks/useMintEmptyBatches";
import { UPDATE_STATUS, useUpdatebatch } from "../../hooks/useUpdateBatch";
import { useEmptyBatchNft, useSetEmptyBatchNft } from "../../store/utils/hook";
import {
  FETCH_CERTIFICATE_STATUS,
  useFetchCertificate,
} from "../../hooks/useFetchCertificate";
import { useSerialStatus } from "../../store/serialStatus/hook";
import { useSaveEmail } from "../../hooks/useSaveEmail";
import { useWeb3React } from "@web3-react/core";

export enum InitializeModal {
  CLOSE,
  INITIATE,
  TRANSFER,
  BRIDGE,
  SUBMIT,
  AFTERSUBMIT,
}

function Initiate() {
  const [initializeModalOpen, setInitializeModalOpen] =
    React.useState<InitializeModal>(InitializeModal.CLOSE);

  const openModelHandler = (value: InitializeModal) => {
    setInitializeModalOpen(value);
  };
  const [serialNumber, setSerialNumber] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>();

  const forwardButtonName = React.useMemo(() => {
    switch (initializeModalOpen) {
      case InitializeModal.CLOSE:
        return "";
      case InitializeModal.INITIATE:
        return "Initiate Batch";
      case InitializeModal.TRANSFER:
        return "Transfer";
      case InitializeModal.BRIDGE:
        return "Bridge";
      case InitializeModal.SUBMIT:
        return "Submit for approval";
      case InitializeModal.AFTERSUBMIT:
        return "Success";
    }
  }, [initializeModalOpen]);

  const serialNumberStatus = useSerialStatus();

  useEffect(() => {
    if (
      serialNumberStatus?.[serialNumber] === "Approved" ||
      serialNumberStatus?.[serialNumber] === "Pending"
    ) {
      setError(true);
    } else setError(false);
  }, [serialNumber, serialNumberStatus]);

  const newNFTId = useEmptyBatchNft();

  const { initiateStatus, triggerEmptyBatchMint } = useMintEmptyBatches();

  const { triggerFetchCertificate, certificateDetails, fetchStatus, fetched } =
    useFetchCertificate();

  const { updateStatus, triggerUpdateBatch } = useUpdatebatch();
  const { triggerEmailUpdate } = useSaveEmail();

  const onChangeSerialNumber = useCallback(
    async (sNumber: string) => {
      if (!sNumber) return;
      await triggerFetchCertificate(sNumber);
    },
    [triggerFetchCertificate],
  );

  const forwardHandler = useCallback(async () => {
    switch (initializeModalOpen) {
      case 0:
        setInitializeModalOpen(InitializeModal.INITIATE);
        break;
      case 1:
        const mintStatus = await triggerEmptyBatchMint();
        if (!mintStatus) {
          break;
        }
        setInitializeModalOpen(InitializeModal.TRANSFER);
        break;
      case 2:
        setInitializeModalOpen(InitializeModal.BRIDGE);
        break;
      case 3:
        if (!fetched && error) {
          alert("Carbon Registry is processing your transaction");
          break;
        }
        setInitializeModalOpen(InitializeModal.SUBMIT);
        break;
      case 4:
        triggerEmailUpdate(email);
        const updateStatus = await triggerUpdateBatch(
          Number(newNFTId),
          String(serialNumber),
          certificateDetails?.quantity,
          `${serialNumber}`,
          email,
        );
        // bridgeNewToken();
        if (!updateStatus) {
          return true;
        }
        setInitializeModalOpen(InitializeModal.AFTERSUBMIT);
        break;
    }
  }, [
    fetched,
    certificateDetails?.quantity,
    newNFTId,
    serialNumber,
    initializeModalOpen,
    triggerEmptyBatchMint,
    triggerUpdateBatch,
    email,
  ]);

  function cancelHandler() {
    setInitializeModalOpen(InitializeModal.CLOSE);
  }

  return (
    <div className="initiate_container">
      <button
        className="initiate"
        onClick={() => {
          openModelHandler(InitializeModal.INITIATE);
        }}
      >
        <img src={Bridge} alt="Initiate" /> <h1>Initiate Bridging</h1>
      </button>
      {initializeModalOpen !== InitializeModal.CLOSE && (
        <InitiateBridgingModal
          email={email}
          setEmail={setEmail}
          fetchLoading={fetchStatus === FETCH_CERTIFICATE_STATUS.LOADING}
          error={error}
          certificateDetails={certificateDetails}
          initializeModalOpen={initializeModalOpen}
          forwardHandler={() => forwardHandler()}
          cancelHandler={() => cancelHandler()}
          forwardButtonName={forwardButtonName}
          serialNumber={String(serialNumber)}
          setSerialNumber={setSerialNumber}
          isClipLoader={
            initiateStatus === INITIATE_STATUS.LOADING ||
            updateStatus === UPDATE_STATUS.LOADING ||
            fetchStatus === FETCH_CERTIFICATE_STATUS.LOADING
          }
          refetchSerialNumber={onChangeSerialNumber}
        />
      )}
    </div>
  );
}

export default Initiate;
