import { useCallback, useEffect, useState } from "react";
import { useTokenContract } from "./useContract";
import { gasPrice, unitParser, unitFormatter } from "../utils";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";
import { getTokenCallData } from "../utils/contract/endcode";
import { getTokenInterface } from "../utils/contract/Interface";

// approval transactions
export enum ApprovalTransactionStatus {
  /** on loading */
  APPROVAL_LOADING,
  /** on confirmed */
  APPROVAL_CONFIRMED,
  /** on error */
  APPROVAL_ERROR,
}

export interface ApprovalResponse {
  /** isApprovalRequired */
  isApprovalRequired: boolean;
  /** transaction status */
  approvalTxStatus: ApprovalTransactionStatus;
  /** approval callback function */
  triggeredApproval: () => Promise<void>;
  /** tx */
  tx?: boolean;
}

// to fetch allowance
export const useAllowance = (
  /** spender address */
  spender: string,
  /** token address */
  token: string,
  /** noCall - true means call is not required */
  noCall?: boolean,
): BigNumber => {
  let tokenContract = useTokenContract(token);
  let [allowance, setAllownace] = useState<BigNumber>();

  const { account } = useWeb3React();

  useEffect(() => {
    if (tokenContract && spender && account) {
      // fetch allowance only once
      const derivedAllowance = async () => {
        try {
          let allowed = await tokenContract.allowance(account, spender);
          setAllownace(allowed);
        } catch (err) {
          console.log("useAllowance Failed", err);
        }
      };
      derivedAllowance();
    }
  }, [tokenContract, account, spender, noCall]);

  return allowance;
};

export const useApproval = (
  allowedAmount: BigNumber,
  /** amount to spend */
  amountToSpend: BigNumber,
  /** spender address */
  spender: string,
  /** approval token Id */
  tokenId: string,
): ApprovalResponse => {
  // to check if approval required

  let [isApprovalRequired, setApprovalRequired] = useState<boolean>(true);
  const [tx, setTx] = useState<boolean>(false);

  let [approvalTxStatus, setApprovalTxStatus] =
    useState<ApprovalTransactionStatus>();
  const { account, library } = useWeb3React();

  useEffect(() => {
    if (!allowedAmount || !amountToSpend) return;
    if (unitFormatter(allowedAmount) > unitFormatter(amountToSpend)) {
      setApprovalRequired(false);
    }
  }, [allowedAmount, amountToSpend]);

  const iface = getTokenInterface();

  const triggeredApproval = useCallback(async () => {
    console.log(amountToSpend, spender, tokenId);
    try {
      if (!account || !spender || !tokenId) return null;
      // set approval status loading

      setApprovalTxStatus(ApprovalTransactionStatus.APPROVAL_LOADING);

      const callData = getTokenCallData(iface, "approve", [
        spender,
        amountToSpend?.toString(),
      ]);

      // estimate gas on
      let gasLimit = await library.getSigner().estimateGas({
        from: account,
        to: tokenId,
        data: callData,
        value: 0,
      });

      // gas price
      let gas_price = await gasPrice(library);

      console.log("gas_price", gas_price, "tokenId", tokenId);

      const approve = await library.getSigner().sendTransaction({
        from: account,
        to: tokenId,
        data: callData,
        gasLimit,
        gasPrice: gas_price,
        value: 0,
      });

      // waiting atleast two confirmation
      await approve.wait(2);
      setTx(approve?.transactionHash);
      setApprovalRequired(false);
      // set approval transaction status to confirm
      setApprovalTxStatus(ApprovalTransactionStatus.APPROVAL_CONFIRMED);
    } catch (err) {
      console.log("useApproval Failed", err);
      // set error
      setApprovalTxStatus(ApprovalTransactionStatus.APPROVAL_ERROR);
    }
  }, [spender, library, account, amountToSpend, iface, tokenId]);

  return {
    isApprovalRequired,
    approvalTxStatus,
    triggeredApproval,
    tx,
  };
};
