import React, { useState } from "react";
import RightArrow from "../../assets/icons/right_arrow.png";
import Stella from "../../assets/icons/stella.png";
import trasactionLogo from "../../assets/icons/transactionLogo.png";
import RetireModal from "../Retire/RetireModal";
import ToolTip from "../ToolTip/ToolTip";
import "./CarbonBundleTokens.css";
import { useWeb3React } from "@web3-react/core";
import { useBuckmintPoolTokens } from "../../store/tokens/hook";

type props = {
  heading: string;
  value: number;
};

function CarbonBundleTokens({ heading, value }: props) {
  const [openRetire, setOpenRetire] = useState<boolean>(false);
  const [retireAmount, setRetireAmount] = useState<number>();
  const [retireStatus, setRetireStatus] = useState<boolean>(false);

  const carbonPoolTokens = useBuckmintPoolTokens();

  const { account, active } = useWeb3React();

  return (
    <div className="transactionBarContainer">
      <h1>
        {heading}
        <ToolTip data="These are “carbon reference bundle” tokens - created by depositing BCO2s in the relevant bundle" />
      </h1>
      <section className="transactionBar">
        <div className="upperBar">
          <h1 className="upperBarIcon">My Balance</h1>
          <h1 className="upperBarIcon">
            TCL{" "}
            <ToolTip data="Total Carbon Locked shows the tonnage of certified carbon reduction or removal locked in a bundle " />
          </h1>
          <h1 className="upperBarIcon">Price</h1>
          <h1 className="upperBarIcon">Action</h1>
        </div>
        <div
          className={
            carbonPoolTokens
              ? "transactionBarContent changetransactionBarContentHeight"
              : "transactionBarContent"
          }
        >
          {carbonPoolTokens?.[0]?.balance1 > 0 ? (
            <div className="carbon_pool_tokens_items">
              <div id="carbon_pool_tokens_balance">
                <img src={Stella} alt="" />
                <div>
                  <h1 className="carbon_pool_tokens_p_heading">
                    {carbonPoolTokens?.[0]?.balance1} BPT
                  </h1>
                  <h3 className="carbon_pool_tokens_s_heading">
                    $ {carbonPoolTokens?.[0]?.balance2}
                  </h3>
                </div>
              </div>
              <div id="carbon_pool_tokens_tcl">
                <h1 className="carbon_pool_tokens_p_heading">
                  {carbonPoolTokens?.[0]?.tclData1}
                </h1>
                <h3 className="carbon_pool_tokens_s_heading">
                  Buckmint Pool Token
                </h3>
              </div>
              <div id="carbon_pool_tokens_price">
                <h1 className="carbon_pool_tokens_p_heading">
                  $ {carbonPoolTokens?.[0]?.price}
                  <img src={RightArrow} alt="right-arrow" />
                </h1>
                <h3 className="carbon_pool_tokens_s_heading">
                  {carbonPoolTokens?.[0]?.reference}
                </h3>
              </div>
              <div id="carbon_pool_tokens_action">
                {Number(carbonPoolTokens?.[0]?.balance1) > 0 ? (
                  <button
                    onClick={() => {
                      setOpenRetire(true);
                      setRetireStatus(false);
                    }}
                    className="carbon_pool_tokens_action_deposit enabled_button"
                  >
                    Retire BPT
                  </button>
                ) : (
                  <button
                    disabled
                    className="carbon_pool_tokens_action_deposit disabled_button"
                  >
                    Retire BPT
                  </button>
                )}
                {openRetire && (
                  <RetireModal
                    setOpenRetire={setOpenRetire}
                    balance={
                      Number(carbonPoolTokens?.[0]?.balance1)
                        ? Number(carbonPoolTokens?.[0]?.balance1)
                        : 0
                    }
                    retireAmount={retireAmount ? retireAmount : 0}
                    setRetireAmount={setRetireAmount}
                  />
                )}
              </div>
            </div>
          ) : (
            <>
              <span className="transactionBarLogo">
                <img
                  src={trasactionLogo}
                  alt=""
                  className="transactionBarImage"
                />
              </span>

              <div className="transactionBarHeading">
                {account && active
                  ? "You have no Carbon Pool Tokens"
                  : "You are not logged in or connected to the wrong network"}
              </div>
              <div className="transactionBarText">
                {account && active
                  ? "Initiate bridging to open a batch."
                  : "Please log in and connect to the right network."}
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default CarbonBundleTokens;
