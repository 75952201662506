import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OrdersMetaData {
  id: string;
  orderId: string;
  quantity: number;
  sellerAddress: string;
  price: number;
  tokenAddress: string;
  projectName: string;
  projectVintage: string;
}

export interface AllowedTokenMetdata {
  projectName: string;
  tokenAddress: string;
  balance: number;
  balance1?: number;
  balance2?: number;
  tclData1?: number;
  tclData2?: string;
  price?: number;
  reference?: string;
  action?: string;
  tokenName?: string;
}

export interface Marketplace {
  allowedTokens: AllowedTokenMetdata[];
  orders: OrdersMetaData[];
}

const initialMarketplace: Marketplace = {
  allowedTokens: null,
  orders: null,
};

const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState: initialMarketplace,
  reducers: {
    setAllowedToken(state, { payload }: PayloadAction<AllowedTokenMetdata[]>) {
      state.allowedTokens = payload;
      return state;
    },
    setMarketplaceOrder(state, { payload }: PayloadAction<OrdersMetaData[]>) {
      state.orders = payload;
      return state;
    },
  },
});

export const { setMarketplaceOrder, setAllowedToken } =
  marketplaceSlice.actions;

export default marketplaceSlice.reducer;
