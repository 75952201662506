import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import trasactionLogo from "../../assets/icons/transactionLogo.png";
import MintSCTModal from "../MintSCT/MintSCTModal";
import ToolTip from "../ToolTip/ToolTip";
import { useBuckMintCO2Batches } from "../../store/tokens/hook";

import { useDeposit } from "../../hooks/useDeposit";
import { BuckmintCo2Batch } from "../../types";

type props = {
  heading: string;
  value: number;
};

function BuckmintCo2Batches({ heading, value }: props) {
  const { account, active } = useWeb3React();
  const location = useLocation();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  /** desired amount that need to deposit in pool */
  const [selectedBCO2, setSelectedBCO2] = useState<BuckmintCo2Batch>();
  const [depositAmount, setDepositAmount] = useState<number>();

  const buckmintCo2Batches = useBuckMintCO2Batches();

  const { depositStatus, triggerDeposit } = useDeposit();

  return (
    <div className="transactionBarContainer">
      <h1>
        {heading}{" "}
        <ToolTip data="These are the BCO2 tokens that have been finalized and fractionalized. " />
      </h1>
      <section className="transactionBar">
        <div className="upperBar">
          <h1 className="upperBarIcon">Balance</h1>
          <h1 className="upperBarIcon">Project Name</h1>
          <h1 className="upperBarIcon">Action</h1>
        </div>
        <div className="transactionBarContent">
          {buckmintCo2Batches && active && account ? (
            buckmintCo2Batches &&
            buckmintCo2Batches?.map((item) => {
              if (item?.balance1 > 0) {
                return (
                  <div className="open_bco2_list_item">
                    <div>
                      <h1>{item.balance1}</h1>
                      <h3>{item.balance2}</h3>
                    </div>
                    <div>
                      <h1>{item.projectName}</h1>
                      <h3>{item.projectDate}</h3>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          setOpenDeposit(true);

                          setSelectedBCO2(item);
                        }}
                        className="carbon_pool_tokens_action_deposit enabled_button"
                      >
                        {item.action}
                      </button>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <>
              <span className="transactionBarLogo">
                <img
                  src={trasactionLogo}
                  alt=""
                  className="transactionBarImage"
                />
              </span>

              <div className="transactionBarHeading">
                {account && active
                  ? "You have no BCO2"
                  : "You are not logged in or connected to the wrong network"}
              </div>
              <div className="transactionBarText">
                {account && active
                  ? "Bridge your credits or redeem a bundle token to receive BCO2."
                  : "Please log in and connect to the right network to see BCO2 Batches."}
              </div>
            </>
          )}

          {openDeposit && (
            <MintSCTModal
              selectedBCO2={selectedBCO2?.tokenAddress}
              buckmintPoolTokenHandler={() =>
                triggerDeposit(
                  selectedBCO2?.tokenAddress,
                  depositAmount ? depositAmount : 0,
                )
              }
              projectName={selectedBCO2?.projectName}
              depositAmount={depositAmount ? depositAmount : 0}
              setDepositAmount={setDepositAmount}
              setOpenDeposit={setOpenDeposit}
              balance={selectedBCO2?.balance1}
              vintageYear={selectedBCO2?.projectDate}
              depositStatus={depositStatus}
            />
          )}
        </div>
      </section>
      {location.pathname === "/bundle" && (
        <div className="footer">
          {page === 1 ? (
            <div>Previous</div>
          ) : (
            <div
              className="footer_button_enable"
              onClick={() => setPage(page - 1)}
            >
              Previous
            </div>
          )}

          <div>{page}</div>
          <div
            className="footer_button_enable"
            onClick={() => setPage(page + 1)}
          >
            Next
          </div>
        </div>
      )}
    </div>
  );
}

export default BuckmintCo2Batches;
