import React from "react";
import "./style.css";
import { ClipLoader } from "react-spinners";
import BCO2 from "../../assets/icons/BCO2.png";
import { OrdersMetaData } from "../../store/marketplace";
import {
  ApprovalResponse,
  ApprovalTransactionStatus,
} from "../../hooks/useApprove";
import { BuyResponse, BuyTransactionStatus } from "../../hooks/useBuyToken";

interface buyModalI {
  closeModal: () => void;
  selectedToken: OrdersMetaData;
  amount: number;
  setAmount: (amount: number) => void;
  buyResponse: BuyResponse;
  approvalResponse: ApprovalResponse;
  usdtBalance: number;
  handleBuy: () => void;
  handleApprove: () => void;
  buyAmountError: boolean;
}

const BuyModal = ({
  closeModal,
  selectedToken,
  amount,
  setAmount,
  approvalResponse,
  buyResponse,
  usdtBalance,
  handleBuy,
  handleApprove,
  buyAmountError,
}: buyModalI) => {
  return (
    <div className="buy_modal_background">
      <div className="buy_modal_Container">
        <>
          <div className="buy-head">Buy Carbon Token </div>
          <div className="buy_modal_body">
            <div className="buy-summary">
              <div className="font-h2">Summary</div>
              {/* <div className="summary-table font-h4">
                <div className="table-left">Project Name</div>
                <div className="table-right">{selectedToken?.projectName}</div>
              </div>
              <div className="summary-table font-h4">
                <div className="table-left">Vintage</div>
                <div className="table-right">
                  {selectedToken?.projectVintage}
                </div>
              </div> */}
              <div className="summary-table font-h4">
                <div className="table-left"> Price ($)</div>
                <div className="table-right">{selectedToken?.price}</div>
              </div>
              <div className="summary-table font-h4">
                <div className="table-left"> Avaialble Quantity </div>
                <div className="table-right">{selectedToken?.quantity}</div>
              </div>
            </div>
            <div className="buy-section">
              <img src={BCO2} width="54px" height="54px" alt="buy-logo" />
              <div className="buycard">
                <div className="font-h3">BPT</div>
                <div className="font-h2">Buckmint Pool Token</div>
              </div>
              <div className="buycard">
                <div className="font-h3">Selling Amount</div>
                <div className="font-h2">
                  <input
                    value={amount}
                    style={{
                      paddingLeft: "5px",
                      border: buyAmountError && "1px solid red",
                    }}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    name="buy-amount"
                    placeholder="enter the quantity"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="balance">
            <div className="font-h3">
              Balance : {usdtBalance > 0 ? usdtBalance : "-"} USDT
            </div>
          </div>
          <div className="buy_modal_footer">
            <button
              className="buy_modal_footer_button"
              onClick={() => closeModal()}
              id="cancelBtn"
            >
              Cancel
            </button>
            <button
              className="buy_modal_footer_button"
              onClick={() => handleApprove()}
              id="approveBtn"
            >
              {" "}
              {approvalResponse.approvalTxStatus ===
              ApprovalTransactionStatus.APPROVAL_LOADING ? (
                <>
                  <ClipLoader color="#32AAAA" size={20} />
                  &nbsp; "Approve"
                </>
              ) : (
                "Approve"
              )}
            </button>
            <button
              className="buy_modal_footer_button filled_button"
              id="retiresct"
              onClick={() => handleBuy()}
            >
              {" "}
              {buyResponse.buyTxStatus === BuyTransactionStatus.BUY_LOADING ? (
                <>
                  <ClipLoader color="white" size={20} />
                  &nbsp; "Buy"
                </>
              ) : (
                "Buy"
              )}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default BuyModal;
