import axios from "axios";
import { useCallback, useState } from "react";
import { spvUrl } from "../config";
import { sendMail } from "../utils/mail";

export enum FETCH_CERTIFICATE_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

export interface CertificateDetails {
  creditType: string;
  location: string;
  methodology: string;
  projectId: string;
  projectName: string;
  projectType: string;
  quantity: number;
  registry: string;
  serialNumber: string;
  vintage: string;
}

interface SPV_API_Response {
  message: string;
  data: CertificateDetails;
}

interface FetchCertificate {
  fetchStatus: FETCH_CERTIFICATE_STATUS;
  fetched: boolean;
  triggerFetchCertificate: (serialNumber: string) => void;
  certificateDetails: CertificateDetails;
  error: string;
}

export const useFetchCertificate = (): FetchCertificate => {
  const [fetchStatus, setFetchStatus] = useState<FETCH_CERTIFICATE_STATUS>();
  const [fetched, setFetched] = useState<boolean>();
  const [certificateDetails, setCertificateDetails] =
    useState<CertificateDetails>();
  const [error, setError] = useState<string>();

  const triggerFetchCertificate = useCallback(async (serialNumber: string) => {
    if (!serialNumber) return null;
    console.log("serialNumber", serialNumber);
    try {
      setFetchStatus(FETCH_CERTIFICATE_STATUS.LOADING);
      setCertificateDetails(null);

      const fetchData = (await axios
        .get(`${spvUrl}/credit/${serialNumber}`)
        .then((data) => data?.data)) as SPV_API_Response;

      console.log("fetchData", fetchData);

      setCertificateDetails(fetchData?.data);
      setFetched(true);
      setFetchStatus(FETCH_CERTIFICATE_STATUS.COMPLETE);
    } catch (err) {
      if (err instanceof Error) {
        console.log("useFetchedCertificate Failed", err?.message);
        setFetchStatus(FETCH_CERTIFICATE_STATUS.ERROR);
        setError(err?.message);
      }
    }
  }, []);

  return {
    fetchStatus,
    fetched,
    triggerFetchCertificate,
    certificateDetails,
    error,
  };
};
