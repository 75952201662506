import { useCallback, useState } from "react";
import { getBaseCarbonTonneInterface } from "../utils/contract/Interface";
import { useWeb3React } from "@web3-react/core";
import { getBaseCarbonTonneCallData } from "../utils/contract/endcode";
import { baseCarbonTonne } from "../config/contracts";
import { gasPrice, unitParser } from "../utils";

export enum DEPOSIT_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

interface DepositResponse {
  depositStatus: DEPOSIT_STATUS;
  triggerDeposit: (tokenAddress: string, amount: number) => Promise<void>;
  transactionHash: string;
}

export const useDeposit = (): DepositResponse => {
  const [depositStatus, setDepositStatus] = useState<DEPOSIT_STATUS>();
  const [transactionHash, setTransactionHash] = useState<string>();
  const iface = getBaseCarbonTonneInterface();
  const { library, account, chainId } = useWeb3React();
  const carbonOffsetBatchAddress = baseCarbonTonne[Number(chainId)];

  const triggerDeposit = useCallback(
    async (tokenAddress: string, amount: number) => {
      if (!iface || !account || !carbonOffsetBatchAddress) return;
      try {
        setDepositStatus(DEPOSIT_STATUS.LOADING);

        const callData = getBaseCarbonTonneCallData(iface, "deposit", [
          tokenAddress,
          unitParser(amount).toString(),
        ]);

        // estimate gas on
        let gasLimit = await library.getSigner().estimateGas({
          from: account,
          to: carbonOffsetBatchAddress,
          data: callData,
          value: 0,
        });

        // gas price
        let gas_price = await gasPrice(library);

        const deposit = await library.getSigner().sendTransaction({
          from: account,
          to: carbonOffsetBatchAddress,
          data: callData,
          gasLimit,
          gasPrice: gas_price,
          value: 0,
        });

        const tx = await deposit.wait(2);
        setDepositStatus(DEPOSIT_STATUS.COMPLETE);
        setTransactionHash(tx?.transactionHash);
      } catch (err) {
        setDepositStatus(DEPOSIT_STATUS.ERROR);
        if (err instanceof Error) {
          console.log("useDeposit failed", err?.message);
        }
      }
    },
    [account, iface, carbonOffsetBatchAddress, library],
  );

  return {
    /** @ts-ignore */
    depositStatus,
    /** @ts-ignore */
    transactionHash,
    triggerDeposit,
  };
};
