import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "..";
import { useCallback, useEffect } from "react";
import {
  setOpenBCO2Batches,
  setBuckMintCO2Batches,
  setBuckmintPoolTokens,
} from ".";
import {
  BuckmintCo2Batch,
  BuckmintPoolToken,
  OpenBco2Batch,
} from "../../types";
import { useWeb3React } from "@web3-react/core";
import { getBCO2Tokens, getMintedBatches } from "../../utils/graphql";
import { formatOffsetBatches } from "../../utils/offsetBatches";
import { formatOpenBCO2Tokens } from "../../utils/bco2tokens";
import { getMulticallBalance } from "../../utils/multicall/bpt";
import { baseCarbonTonne } from "../../config/contracts";
import { unitFormatter } from "../../utils";
import { useProtocolData } from "../protocol/hook";
import { useAppChainId } from "../application/hook";

export const useSetMintBC02Batches = () => {
  const { account, chainId } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!account) return;
    const fetchMintedBatches = async () => {
      const mintedBatches = await getMintedBatches(
        Number(chainId),
        account,
      ).then((value) => value);
      if (mintedBatches) {
        const formattedBatches = await formatOffsetBatches(
          mintedBatches,
          chainId,
          account,
        );
        dispatch(setOpenBCO2Batches(formattedBatches));
      }
    };
    fetchMintedBatches();
  }, [account, chainId, dispatch]);
};

export const useSetBCO2Tokens = () => {
  const appChainId = useAppChainId();
  const dispatch = useDispatch<AppDispatch>();
  const { account, chainId } = useWeb3React();

  useEffect(() => {
    if (!appChainId) return;
    const fetchOpenBCO2Tokens = async () => {
      const bco2Tokens = await getBCO2Tokens(Number(appChainId)).then(
        (value) => value,
      );
      if (bco2Tokens) {
        const formattedOpenBCO2Tokens = await formatOpenBCO2Tokens(
          bco2Tokens,
          chainId,
          account,
        );
        dispatch(setBuckMintCO2Batches(formattedOpenBCO2Tokens));
      }
    };
    fetchOpenBCO2Tokens();
  }, [appChainId, account, chainId, dispatch]);
};

export const useSetBPTTokens = () => {
  const { account, chainId } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  const protocolData = useProtocolData();

  useEffect(() => {
    if (!account) return;
    const fetchOpenBCO2Tokens = async () => {
      const fetchBPTBalance = await getMulticallBalance(
        chainId,
        account,
        baseCarbonTonne[chainId],
      );
      if (fetchBPTBalance) {
        dispatch(
          setBuckmintPoolTokens([
            {
              balance1: unitFormatter(
                fetchBPTBalance?.[baseCarbonTonne[chainId].toLowerCase()],
              ),
              balance2:
                unitFormatter(
                  fetchBPTBalance?.[baseCarbonTonne[chainId].toLowerCase()],
                ) * 10, // balance in usd
              tclData1: protocolData?.totalBPT,
              tokenAddress: baseCarbonTonne?.[chainId],
              tokenName: "Buckmint Pool Token",
              tclData2: "0",
              price: 10,
              reference: "Sushiwap",
              action: "Reedem BCO2",
            },
          ]),
        );
      }
    };
    fetchOpenBCO2Tokens();
  }, [account, chainId, dispatch, protocolData?.totalBPT]);
};

export const useSetOpenBC02Batches = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (openbco2Batches: OpenBco2Batch[]) => {
      dispatch(setOpenBCO2Batches(openbco2Batches));
    },
    [dispatch],
  );
};

export const useSetBuckMintCO2Batches = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (buckMintBCO2Batches: BuckmintCo2Batch[]) => {
      dispatch(setBuckMintCO2Batches(buckMintBCO2Batches));
    },
    [dispatch],
  );
};

export const useSetCarbonPoolTokens = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (buckmintPoolTokens: BuckmintPoolToken[]) => {
      dispatch(setBuckmintPoolTokens(buckmintPoolTokens));
    },
    [dispatch],
  );
};

export const useBuckMintCO2Batches = () => {
  return useSelector((state: AppState) => state.tokens.buckmintCo2Batches);
};

export const useBuckmintPoolTokens = () => {
  return useSelector((state: AppState) => state.tokens.buckmintPoolTokens);
};

export const useOpenBC02Batches = () => {
  return useSelector((state: AppState) => state.tokens.openBco2Batches);
};
