import {
  getBuckmintGraphqlClient,
  getBuckmintMarketplaceGraphqlClient,
} from "../../graphql";
import {
  allSerialNumberQuery,
  allowedTokenQuery,
  batchMintedQuery,
  bco2TokenQuery,
  buckmintProtocolDataQuery,
  listedOrderQuery,
  projectMintedQuery,
} from "../../graphql/query";
import {
  AllowedToken,
  BCO2Tokens,
  Batch,
  BuckmintProtocolData,
  MintedProject,
  Order,
  SerialStatus,
} from "../../graphql/types";

export const getMintedBatches = async (
  chainId: number,
  user: string,
): Promise<Batch[] | null> => {
  if (!user) return null;
  const client = getBuckmintGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ batches: Batch[] }>({
    query: batchMintedQuery,
    variables: {
      user,
      first: 1000,
    },
  });

  return results?.data?.batches as Batch[];
};

export const getBCO2Tokens = async (
  chainId: number,
): Promise<BCO2Tokens[] | null> => {
  if (!chainId) return null;
  const client = getBuckmintGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ bco2Tokens: BCO2Tokens[] }>({
    query: bco2TokenQuery,
    variables: {
      first: 1000,
    },
  });

  return results?.data?.bco2Tokens as BCO2Tokens[];
};

export const getBuckmintProtocolData = async (
  chainId: number,
): Promise<BuckmintProtocolData | null> => {
  if (!chainId) return null;
  const client = getBuckmintGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ protocolDatas: BuckmintProtocolData }>({
    query: buckmintProtocolDataQuery,
    variables: {
      first: 1000,
    },
  });

  return results?.data?.protocolDatas as BuckmintProtocolData;
};

export const getMintedProjects = async (
  chainId: number,
): Promise<MintedProject[] | null> => {
  const client = getBuckmintGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ mintedProjects: MintedProject[] }>({
    query: projectMintedQuery,
    variables: {
      first: 1000,
    },
  });

  return results?.data?.mintedProjects as MintedProject[];
};

export const getAllSerialNumber = async (
  chainId: number,
): Promise<SerialStatus[] | null> => {
  const client = getBuckmintGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ batches: SerialStatus[] }>({
    query: allSerialNumberQuery,
    variables: {
      first: 1000,
    },
  });

  return results?.data?.batches as SerialStatus[];
};

export const getMarketplaceOrders = async (
  chainId: number,
): Promise<Order[] | null> => {
  const client = getBuckmintMarketplaceGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ listedOrders: Order[] }>({
    query: listedOrderQuery,
    variables: {
      first: 1000,
    },
  });

  console.log("data", results.data);

  return results?.data?.listedOrders as Order[];
};

export const getAllowedToken = async (
  chainId: number,
): Promise<AllowedToken[] | null> => {
  const client = getBuckmintMarketplaceGraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ allowedTokens: AllowedToken[] }>({
    query: allowedTokenQuery,
    variables: {
      first: 1000,
    },
  });

  return results?.data?.allowedTokens as AllowedToken[];
};
