import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "..";
import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { POLYGON_CHAIN, chainsConfig } from "../../config/chains";
import { setAppchainId } from ".";
import { hexlify } from "ethers";
import { InjectedConnector } from "@web3-react/injected-connector";

export const useChangeAppChainId = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { connector, library } = useWeb3React();
  return useCallback(
    async (newAppChainId: number, noSwitch?: boolean) => {
      const isMetaMask = window.ethereum && window.ethereum.isMetaMask;
      try {
        if (connector instanceof InjectedConnector && isMetaMask) {
          const ethereum = window.ethereum;
          if (newAppChainId === POLYGON_CHAIN && !noSwitch) {
            await ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x13881" }],
            });
          }
        }
        dispatch(
          setAppchainId({
            chainId: newAppChainId,
          }),
        );
        return true;
      } catch (err) {
        if (err.code === 4902) {
          const ethereum = window?.ethereum;
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: chainsConfig[newAppChainId],
          });
        }
        console.log("Error in useChangeAppChainId(): ", err.message);
        return false;
      }
    },
    [dispatch, connector, library],
  );
};

export const useAppChainId = () => {
  return useSelector((state: AppState) => state.application.chainId);
};
