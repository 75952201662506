import axios from "axios";
import { useCallback, useState } from "react";
import { spvUrl } from "../config";
import { useWeb3React } from "@web3-react/core";

export enum UPDATE_EMAIL_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

interface SaveEmail {
  updateEmailStatus: UPDATE_EMAIL_STATUS;
  triggerEmailUpdate: (email: string) => void;
  error: string;
}

export const useSaveEmail = (): SaveEmail => {
  const [updateEmailStatus, setUpdateEmailStatus] =
    useState<UPDATE_EMAIL_STATUS>();
  const [error, setError] = useState<string>();
  const { account } = useWeb3React();

  const triggerEmailUpdate = useCallback(
    async (email: string) => {
      if (!account || !email) return null;
      try {
        setUpdateEmailStatus(UPDATE_EMAIL_STATUS.LOADING);

        await axios
          .post(`${spvUrl}/data/add-emails`, {
            walletAddress: account,
            email: email,
          })
          .then((data) => data?.data);

        setUpdateEmailStatus(UPDATE_EMAIL_STATUS.COMPLETE);
      } catch (err) {
        if (err instanceof Error) {
          console.log("useFetchedCertificate Failed", err?.message);
          setUpdateEmailStatus(UPDATE_EMAIL_STATUS.ERROR);
          setError(err?.message);
        }
      }
    },
    [account],
  );

  return {
    updateEmailStatus,
    triggerEmailUpdate,
    error,
  };
};
