import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import trasactionLogo from "../../assets/icons/transactionLogo.png";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import InitiateBridgingModal from "../Initiate/InitiateBridgingModal/InitiateBridgingModal";
import ToolTip from "./../ToolTip/ToolTip";
import "./OpenBco2Batches.css";
import { useOpenBC02Batches } from "../../store/tokens/hook";
import {
  useMintEmptyBatches,
  INITIATE_STATUS,
} from "../../hooks/useMintEmptyBatches";
import { useUpdatebatch, UPDATE_STATUS } from "../../hooks/useUpdateBatch";
import { RetirementStatus } from "../../graphql/types";
import { useEmptyBatchNft, useSetEmptyBatchNft } from "../../store/utils/hook";
import { useFractionlize } from "../../hooks/useFractionlize";
import {
  FETCH_CERTIFICATE_STATUS,
  useFetchCertificate,
} from "../../hooks/useFetchCertificate";
import { useSerialStatus } from "../../store/serialStatus/hook";
import { useSaveEmail } from "../../hooks/useSaveEmail";
import { OpenBco2Batch } from "../../types";

export enum InitializeModal {
  CLOSE,
  INITIATE,
  TRANSFER,
  BRIDGE,
  SUBMIT,
  AFTERSUBMIT,
}

function OpenBco2Batches(data: any) {
  const { account, active } = useWeb3React();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [selectedToken, setSelectedToken] = useState<number>(0);
  const [error, setError] = useState<boolean>();
  const [email, setEmail] = useState<string>();

  const openBco2Batches = useOpenBC02Batches();

  // React.useEffect(() => {
  //   setOpenBco2Batches(openBco2BatchesArray);
  // }, [openBco2BatchesArray]);

  const newNFTId = useEmptyBatchNft();

  const { initiateStatus, triggerEmptyBatchMint } = useMintEmptyBatches();

  const { updateStatus, triggerUpdateBatch } = useUpdatebatch();

  const { triggerFetchCertificate, certificateDetails, fetchStatus, fetched } =
    useFetchCertificate();

  const { triggerEmailUpdate } = useSaveEmail();

  function getStatusClass(status: string): string {
    if (status === "Initialized") return "Initialised";
    if (status === "Pending") return "Pending";
    if (status === "Rejected") return "Cancelled";
    if (status === "Approved") return "Approved";
    if (status === "Closed") return "Closed";
    return "";
  }
  const [initializeModalOpen, setInitializeModalOpen] =
    React.useState<InitializeModal>(InitializeModal.CLOSE);

  const openModelHandler = (value: InitializeModal) => {
    setInitializeModalOpen(value);
  };

  const forwardButtonName = React.useMemo(() => {
    switch (initializeModalOpen) {
      case InitializeModal.CLOSE:
        return "";
      case InitializeModal.INITIATE:
        return "Initiate Batch";
      case InitializeModal.TRANSFER:
        return "Transfer";
      case InitializeModal.BRIDGE:
        return "Bridge";
      case InitializeModal.SUBMIT:
        return "Submit for approval";
      case InitializeModal.AFTERSUBMIT:
        return "Success";
    }
  }, [initializeModalOpen]);

  // const updateOpenBCO2Batches = useUpdateOpenBC02Batches();

  // const bridgeNewToken = () => {
  //   updateOpenBCO2Batches({
  //     id: projects[randomNumber]?.projectId,
  //     balance1: numberOfTokens,
  //     balance2: String(numberOfTokens),
  //     projectName: `${projects[randomNumber]?.projectName}, ${projects[randomNumber]?.country}`,
  //     projectDate: "01/01/2018",
  //     status: RetirementStatus.Initialized,
  //     action: "Convert",
  //   });
  // };

  const onChangeSerialNumber = useCallback(
    async (sNumber: string) => {
      if (!sNumber) return;
      await triggerFetchCertificate(sNumber);
    },
    [triggerFetchCertificate],
  );

  const forwardHandler = useCallback(async () => {
    switch (initializeModalOpen) {
      case 0:
        setInitializeModalOpen(InitializeModal.INITIATE);
        break;
      case 1:
        const mintStatus = await triggerEmptyBatchMint();
        if (!mintStatus) {
          break;
        }
        setInitializeModalOpen(InitializeModal.TRANSFER);
        break;
      case 2:
        setInitializeModalOpen(InitializeModal.BRIDGE);
        break;
      case 3:
        if (!fetched && error) {
          alert("Carbon Registry is processing your transaction");
          break;
        }
        setInitializeModalOpen(InitializeModal.SUBMIT);
        break;
      case 4:
        triggerEmailUpdate(email);
        const updateStatus = await triggerUpdateBatch(
          Number(newNFTId),
          String(serialNumber),
          certificateDetails?.quantity,
          `${serialNumber}`,
          email,
        );
        // bridgeNewToken();
        if (!updateStatus) {
          return true;
        }
        setInitializeModalOpen(InitializeModal.AFTERSUBMIT);
        break;
    }
  }, [
    error,
    fetched,
    certificateDetails?.quantity,
    newNFTId,
    serialNumber,
    initializeModalOpen,
    triggerEmptyBatchMint,
    triggerUpdateBatch,
    email,
    triggerEmailUpdate,
  ]);

  function cancelHandler() {
    setInitializeModalOpen(InitializeModal.CLOSE);
  }

  // const updateBCO2Batches = useUpdateBCO2Batches();
  // const removeBCO2 = useRemoveOpenBC02Batches();

  const { fractionlizeStatus, triggerFractionlize } = useFractionlize();
  const [selectedBCO2Token, setSelectedBCO2Token] = useState<OpenBco2Batch>();

  const convertHandler = (tokenId: number) => {
    triggerFractionlize(tokenId);
  };

  const setEmptyBatchNft = useSetEmptyBatchNft();

  const serialNumberStatus = useSerialStatus();

  useEffect(() => {
    if (
      serialNumberStatus?.[serialNumber] === "Approved" ||
      serialNumberStatus?.[serialNumber] === "Pending"
    ) {
      setError(true);
    } else setError(false);
  }, [serialNumber, serialNumberStatus]);

  return (
    <div className="transactionBarContainer">
      <h1>
        {data.heading}{" "}
        <ToolTip data="These are the batches that are in the process of being bridged or have been finalized." />
      </h1>
      <section className="transactionBar">
        <div className="upperBar">
          <h1 className="upperBarIcon">ID</h1>
          <h1 className="upperBarIcon">Balance</h1>
          <h1 className="upperBarIcon">
            Project Name{" "}
            <ToolTip data="The name of the project as per the standard body." />
          </h1>
          <h1 className="upperBarIcon">Status</h1>
          <h1 className="upperBarIcon">Action</h1>
        </div>

        <div className="transactionBarContent">
          {active && account ? (
            openBco2Batches &&
            openBco2Batches.map((item) => {
              return (
                <div className="open_bco2_list_item">
                  <div>
                    <h1>{`BM-BATCH-${item.id}`}</h1>
                  </div>
                  <div>
                    <h1>{item.balance1}</h1>
                    <h3>{item.balance2}</h3>
                  </div>
                  <div>
                    <h1>{item.projectName}</h1>
                    <h3>{item.projectDate}</h3>
                  </div>
                  <div>
                    <div
                      className={`status ${getStatusClass(
                        item.status.toString(),
                      )}`}
                    >
                      {item.status}
                    </div>
                  </div>
                  <div>
                    <div className="initiate_container">
                      {item.status === RetirementStatus.Initialized && (
                        <button
                          onClick={() => {
                            openModelHandler(InitializeModal.BRIDGE);
                            setEmptyBatchNft(Number(item?.id));
                          }}
                          className="carbon_pool_tokens_action_deposit enabled_button"
                        >
                          Attach Serial
                        </button>
                      )}
                      {initializeModalOpen !== InitializeModal.CLOSE && (
                        <InitiateBridgingModal
                          error={error}
                          fetchLoading={
                            fetchStatus === FETCH_CERTIFICATE_STATUS.LOADING
                          }
                          certificateDetails={certificateDetails}
                          initializeModalOpen={initializeModalOpen}
                          forwardHandler={() => forwardHandler()}
                          cancelHandler={() => cancelHandler()}
                          forwardButtonName={forwardButtonName}
                          serialNumber={String(serialNumber)}
                          setSerialNumber={setSerialNumber}
                          isClipLoader={
                            initiateStatus === INITIATE_STATUS.LOADING ||
                            updateStatus === UPDATE_STATUS.LOADING
                          }
                          email={email}
                          setEmail={setEmail}
                          refetchSerialNumber={onChangeSerialNumber}
                        />
                      )}
                    </div>
                    {item.status === RetirementStatus.Approved && (
                      <button
                        onClick={() => {
                          setOpenConfirm(true);
                          setSelectedToken(parseInt(item?.id));
                          setSelectedBCO2Token(item);
                        }}
                        className="carbon_pool_tokens_action_deposit enabled_button"
                      >
                        Convert
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <span className="transactionBarLogo">
                <img
                  src={trasactionLogo}
                  alt=""
                  className="transactionBarImage"
                />
              </span>

              <div className="transactionBarHeading">
                {account && active
                  ? "You have no open BCO2 batches"
                  : "You are not logged in or connected to the wrong network"}
              </div>
              <div className="transactionBarText">
                {account && active
                  ? "Initiate bridging to open a batch."
                  : "Please log in and connect to the right network to see BCO2 Batches."}
              </div>
            </>
          )}

          <>
            {openConfirm && (
              <ConfirmModal
                key={selectedBCO2Token?.id}
                fractionlizeStatus={fractionlizeStatus}
                setOpenConfirm={setOpenConfirm}
                convertHandler={() => convertHandler(selectedToken)}
                batchId={parseInt(selectedBCO2Token?.id)}
              />
            )}
          </>
        </div>
      </section>
      {location.pathname === "/bridge" && (
        <div className="footer">
          {page === 1 ? (
            <div>Previous</div>
          ) : (
            <div
              className="footer_button_enable"
              onClick={() => setPage(page - 1)}
            >
              Previous
            </div>
          )}

          <div>{page}</div>
          <div
            className="footer_button_enable"
            onClick={() => setPage(page + 1)}
          >
            Next
          </div>
        </div>
      )}
    </div>
  );
}

export default OpenBco2Batches;
