import { useCallback, useEffect, useState } from "react";
import { gasPrice, unitParser, unitFormatter } from "../utils";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";
import { getTokenCallData } from "../utils/contract/endcode";
import { getMarketplaceInterface } from "../utils/contract/Interface";
import { marketPlaceContract } from "../config/marketplace";

// buy transactions
export enum BuyTransactionStatus {
  /** on loading */
  BUY_LOADING,
  /** on confirmed */
  BUY_CONFIRMED,
  /** on error */
  BUY_ERROR,
}

export interface BuyResponse {
  /** transaction status */
  buyTxStatus: BuyTransactionStatus;
  /** buy callback function */
  triggeredBuy: () => Promise<void>;
  /** tx */
  tx?: boolean;
}

export const useBuy = (
  /** bco2 token address */
  orderId: number,
  /** qunatity to list for buy */
  quantity: number,
): BuyResponse => {
  const [tx, setTx] = useState<boolean>(false);
  let [buyTxStatus, setBuyTxStatus] = useState<BuyTransactionStatus>();
  const { account, library, chainId } = useWeb3React();

  const iface = getMarketplaceInterface();
  const marketplaceAddress = marketPlaceContract?.[chainId];

  const triggeredBuy = useCallback(async () => {
    try {
      if (!account || !quantity || !marketplaceAddress) return null;
      // set buy status loading

      setBuyTxStatus(BuyTransactionStatus.BUY_LOADING);

      const callData = getTokenCallData(iface, "buy", [
        orderId,
        unitParser(quantity),
      ]);

      // estimate gas on
      let gasLimit = await library.getSigner().estimateGas({
        from: account,
        to: marketplaceAddress,
        data: callData,
        value: 0,
      });

      // gas price
      let gas_price = await gasPrice(library);

      const buy = await library.getSigner().sendTransaction({
        from: account,
        to: marketplaceAddress,
        data: callData,
        gasLimit,
        gasPrice: gas_price,
        value: 0,
      });

      // waiting atleast two confirmation
      await buy.wait(2);
      setTx(buy?.transactionHash);
      // set buy transaction status to confirm
      setBuyTxStatus(BuyTransactionStatus.BUY_CONFIRMED);
    } catch (err) {
      console.log("useBuy Failed", err);
      // set error
      setBuyTxStatus(BuyTransactionStatus.BUY_ERROR);
    }
  }, [orderId, library, account, iface, marketplaceAddress, quantity]);

  return {
    buyTxStatus,
    triggeredBuy,
    tx,
  };
};
