import { unitFormatter } from ".";
// import { carbonProjects } from "../config/contracts";
import { BCO2Tokens } from "../graphql/types";
import { BuckmintCo2Batch } from "../types";
import { getMulticallBalance } from "./multicall/bco2Tokens";
// import { getMulticallProject } from "./multicall/projects";
import { projectsData } from "../config/projects";

export const formatOpenBCO2Tokens = async (
  bco2Projects: BCO2Tokens[],
  chainId: number,
  account: string,
): Promise<BuckmintCo2Batch[]> => {
  const tokenAddresses: string[] = [];
  const projectVintageIds: string[] = [];
  for (let i = 0; i < bco2Projects.length; i++) {
    tokenAddresses.push(bco2Projects?.[i]?.token);
    projectVintageIds.push(
      bco2Projects?.[i]?.projectVintageTokenId?.toString(),
    );
  }

  let multicallBalance: { [wallet: string]: string } = {};

  if (account) {
    multicallBalance = await getMulticallBalance(
      chainId,
      account,
      tokenAddresses,
    );
  }

  const formattedBco2Batches: BuckmintCo2Batch[] = bco2Projects?.map((el) => {
    return {
      id: el?.projectVintageTokenId?.toString(),
      tokenAddress: el?.token?.toLowerCase(),
      balance1: unitFormatter(multicallBalance?.[el?.token?.toLowerCase()]),
      balance2: unitFormatter(
        multicallBalance?.[el?.token?.toLowerCase()],
      )?.toString(),
      projectName:
        projectsData?.[Number(el?.projectVintageTokenId)]?.projectName,
      projectDate:
        projectsData?.[Number(el?.projectVintageTokenId)]?.projectDate,
      action: "Mint BPT",
      projectId: Number(el?.projectVintageTokenId),
    };
  });

  return formattedBco2Batches;
};
