import React from "react";
import chain from "../../assets/icons/chain.png";
import Drop from "../../assets/icons/dropdown.png";
import "./Testnet.css";
import { useWeb3React } from "@web3-react/core";
import { useChangeAppChainId } from "../../store/application/hook";
import { POLYGON_CHAIN } from "../../config/chains";

function Testnet() {
  const [open, setOpen] = React.useState<boolean>(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const handleDropDownFocus = (state: boolean) => {
    setOpen(!state);
  };
  const handleClickOutsideDropdown = (e: any) => {
    if (open && !dropdownRef.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  };
  window.addEventListener("click", handleClickOutsideDropdown);

  const { chainId } = useWeb3React();
  const switchChainId = useChangeAppChainId();

  return (
    <div className="_testnet_container">
      <div
        className={
          Number(chainId) === POLYGON_CHAIN
            ? "testnet_drop_container"
            : "testnet_drop_container_wrong_network"
        }
        ref={dropdownRef}
      >
        <button onClick={(e) => handleDropDownFocus(open)}>
          {Number(chainId) === POLYGON_CHAIN && (
            <img src={chain} alt="connect" />
          )}
          <h1>
            {Number(chainId) === POLYGON_CHAIN ? `MUMBAI` : `SWITCH NETWORK`}
          </h1>
          <img src={Drop} alt="" />
        </button>
        {open && (
          <ul>
            <li
              onClick={
                Number(chainId) === POLYGON_CHAIN
                  ? () => handleClickOutsideDropdown(dropdownRef)
                  : () => {
                      switchChainId(POLYGON_CHAIN);
                      handleClickOutsideDropdown(dropdownRef);
                    }
              }
            >
              <img src={chain} alt="connect" />
              <h1> MUMBAI</h1>
            </li>
          </ul>
        )}
      </div>
      {Number(chainId) !== POLYGON_CHAIN && (
        <p style={{ color: "red", fontSize: 14 }}> Switch to Polygon Mumbai</p>
      )}
    </div>
  );
}

export default Testnet;
