import Connect from "../../components/ConnectWallet/ConnectWallet";
import Initiate from "../../components/Initiate/Initiate";
import OpenBco2Batches from "../../components/OpenBco2Batches/OpenBco2Batches";
import Refresh from "../../components/Refresh/Refresh";
import Testnet from "../../components/Testnet/Testnet";
import "./Bridge.css";

function Bridge() {
  return (
    <div className="bridge_home home">
      <div className="bridge_section1 section1">
        <Testnet />
        <Connect />
      </div>
      <div className="bridge_section3 section3">
        <h1>My Bridge Assets</h1>
        <div>
          <Refresh />
          <Initiate />
        </div>
      </div>
      <div className="bridge_section4 section4">
        <OpenBco2Batches heading="Open BCO2 Batches" value={15} />
      </div>
    </div>
  );
}

export default Bridge;
