import { useCallback, useState } from "react";
import { getCarbonOffsetBatchesInterface } from "../utils/contract/Interface";
import { useWeb3React } from "@web3-react/core";
import { getCarbonOffsetBatchesEncodedCallData } from "../utils/contract/endcode";
import { carbonOffsetBatcheAddress } from "../config/contracts";
import { gasPrice } from "../utils";

export enum FRACTIONALIZE_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

interface InitiateBridgingResponse {
  fractionlizeStatus: FRACTIONALIZE_STATUS;
  triggerFractionlize: (tokenId: number) => Promise<void>;
  transactionHash: string;
}

export const useFractionlize = (): InitiateBridgingResponse => {
  const [fractionlizeStatus, setFractionlizeStatus] =
    useState<FRACTIONALIZE_STATUS>();
  const [transactionHash, setTransactionHash] = useState<string>();
  const iface = getCarbonOffsetBatchesInterface();
  const { library, account, chainId } = useWeb3React();
  const carbonOffsetBatchAddress = carbonOffsetBatcheAddress[Number(chainId)];

  const triggerFractionlize = useCallback(
    async (tokenId: number) => {
      if (!iface || !account || !carbonOffsetBatchAddress) return;
      try {
        setFractionlizeStatus(FRACTIONALIZE_STATUS.LOADING);

        const callData = getCarbonOffsetBatchesEncodedCallData(
          iface,
          "fractionalize",
          [tokenId],
        );

        // estimate gas on
        let gasLimit = await library.getSigner().estimateGas({
          from: account,
          to: carbonOffsetBatchAddress,
          data: callData,
          value: 0,
        });

        // gas price
        let gas_price = await gasPrice(library);

        const fraction = await library.getSigner().sendTransaction({
          from: account,
          to: carbonOffsetBatchAddress,
          data: callData,
          gasLimit,
          gasPrice: gas_price,
          value: 0,
        });

        const tx = await fraction.wait(2);
        setFractionlizeStatus(FRACTIONALIZE_STATUS.COMPLETE);
        setTransactionHash(tx?.transactionHash);
      } catch (err) {
        setFractionlizeStatus(FRACTIONALIZE_STATUS.ERROR);
        if (err instanceof Error) {
          console.log("useFractionlize failed", err?.message);
        }
      }
    },
    [account, iface, carbonOffsetBatchAddress, library],
  );

  return {
    /** @ts-ignore */
    fractionlizeStatus,
    /** @ts-ignore */
    transactionHash,
    /** @ts-ignore */
    triggerFractionlize,
  };
};
