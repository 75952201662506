import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";

export interface Application {
  chainId: number;
}

const initialNotes: Application = {
  chainId: 80001,
};

const notesSlice = createSlice({
  name: "application",
  initialState: initialNotes,
  reducers: {
    setAppchainId(state, { payload }: PayloadAction<{ chainId: number }>) {
      state.chainId = payload.chainId;
      return state;
    },
  },
});

export const { setAppchainId } = notesSlice.actions;

export default notesSlice.reducer;
