import React, { useState } from "react";
import ArrowUp from "../../../../assets/icons/arrowDown.png";
import ArrowDown from "../../../../assets/icons/arrowUp.png";
import Info from "../../../../assets/icons/info.png";
import Tick from "../../../../assets/icons/tick.png";
import "./InitiatePage.css";
import {
  carbonRegistries,
  carbonRegistryWalletOption,
} from "../../../../config/registry";

interface InititatePageProps {
  selectedRegistry: number;
  selectedAccessOption: number;
  setSelectedRegistry: (selectedRegistry: number) => void;
  setSelectedAccessOption: (selectedAccessOption: number) => void;
}

export const InitiatePage = ({
  selectedRegistry,
  setSelectedRegistry,
  selectedAccessOption,
  setSelectedAccessOption,
}: InititatePageProps) => {
  const [isRegistryDropDown, setIsRegistryDropDown] = useState<boolean>(false);
  const [isRegistryAccessDropDown, setIsRegistryAccessDropDown] =
    useState<boolean>(false);

  const dropdownRefProvider = React.useRef<HTMLDivElement>(null);
  const handleDropDownFocusProvider = (state: boolean) => {
    setIsRegistryDropDown(!state);
  };
  const handleClickOutsideDropdownProvider = (e: any) => {
    if (
      isRegistryDropDown &&
      !dropdownRefProvider.current?.contains(e.target as Node)
    ) {
      setIsRegistryDropDown(false);
    }
  };
  window.addEventListener("click", handleClickOutsideDropdownProvider);

  const dropdownRefAccess = React.useRef<HTMLDivElement>(null);
  const handleDropDownFocussetAccess = () => {
    setIsRegistryAccessDropDown(!isRegistryAccessDropDown);
  };

  const handleClickOutsideDropdownAccess = (e: any) => {
    if (
      isRegistryAccessDropDown &&
      !dropdownRefAccess.current?.contains(e.target as Node)
    ) {
      setIsRegistryAccessDropDown(false);
    }
  };

  const handleSelectCarbonRegistry = (index: number) => {
    setSelectedRegistry(index);
    setIsRegistryDropDown(!isRegistryDropDown);
  };

  const handleSelectWalletOptions = (index: number) => {
    setSelectedAccessOption(index);
    setIsRegistryAccessDropDown(!isRegistryAccessDropDown);
  };

  window.addEventListener("click", handleClickOutsideDropdownAccess);
  return (
    <div className="initiate_page">
      <h1 className="initiate_page_heading">Initiate New Batch</h1>
      <div className="initiate_blue_box">
        <div>
          <img src={Info} alt="" />
          <h1> Please note</h1>
        </div>
        <ol>
          <li>
            Before bridging, make sure you have read and understood the
            acceptance criteria for our BPT pool if you're planning to deposit
            your bridged credits into them.
          </li>
          <li>
            New: Bridging credits with a difference of more than 5 years between
            vintage end date and issuance date will be rejected. For details,
            see here .
          </li>
        </ol>
      </div>
      <div className="initiate_page_dropdowns">
        <h1 className="initiate_page_dropdowns_heading">Provider</h1>
        <p className="initiate_page_dropdowns_details">
          Select the registry from which you are bridging the credits from
        </p>
        <div className="initiate_dropdown_button">
          <div
            className="initiate_dropdown_button_container"
            ref={dropdownRefProvider}
          >
            <button
              onClick={() => handleDropDownFocusProvider(isRegistryDropDown)}
            >
              <h1>
                {selectedRegistry === -1
                  ? "Please Select"
                  : carbonRegistries[selectedRegistry].name}
              </h1>
              {isRegistryDropDown ? (
                <img src={ArrowDown} alt="arrow-down" />
              ) : (
                <img src={ArrowUp} alt="arrow-up" />
              )}
            </button>
            {isRegistryDropDown && (
              <ul>
                {carbonRegistries?.map((el, i) => {
                  return (
                    <li
                      onClick={() => {
                        handleSelectCarbonRegistry(i);
                        handleClickOutsideDropdownProvider(dropdownRefProvider);
                      }}
                    >
                      <h1>{el?.name}</h1>
                      {i === selectedRegistry && <img src={Tick} alt="" />}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="initiate_page_dropdowns">
        <h1 className="initiate_page_dropdowns_heading">Access</h1>
        <p className="initiate_page_dropdowns_details">
          Do you have access to the registry, or have a partner who can access
          the registry?
        </p>
        <div className="initiate_dropdown_button">
          <div
            className="initiate_dropdown_button_container"
            ref={dropdownRefAccess}
          >
            <button onClick={(e) => handleDropDownFocussetAccess()}>
              <h1>
                {selectedAccessOption === -1
                  ? "Please Select"
                  : carbonRegistryWalletOption[selectedAccessOption]}
              </h1>
              {isRegistryAccessDropDown ? (
                <img src={ArrowDown} alt="arrow-down" />
              ) : (
                <img src={ArrowUp} alt="arrow-up" />
              )}
            </button>
            {isRegistryAccessDropDown && (
              <ul>
                {carbonRegistryWalletOption?.map((el, i) => {
                  return (
                    <li
                      onClick={() => {
                        handleSelectWalletOptions(i);
                        handleClickOutsideDropdownAccess(dropdownRefAccess);
                      }}
                    >
                      <h1>{el}</h1>
                      {i === selectedAccessOption && <img src={Tick} alt="" />}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiatePage;
