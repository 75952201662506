import React, { useMemo, useState } from "react";
import { InitializeModal } from "../Initiate";
import AfterSubmitPage from "./AfterSubmitPage/AfterSubmitPage";
import BridgePage from "./BridgePage/BridgePage";
import InitiatePage from "./InitiatePage/InitiatePage";
import SubmitPage from "./SubmitPage/SubmitPage";
import TransferPage from "./TransferPage/TransferPage";

import "./InitiateBridgingModal.css";
import { isValidEmailAddress } from "../../../utils";
import { useEmptyBatchNft } from "../../../store/utils/hook";
import { ClipLoader } from "react-spinners";
import { CertificateDetails } from "../../../hooks/useFetchCertificate";

export interface InitializeBridgeModelProps {
  initializeModalOpen: InitializeModal;
  forwardHandler: () => void;
  cancelHandler: () => void;
  forwardButtonName: string;
  serialNumber: string;
  setSerialNumber: (serialNumber: string) => void;
  isClipLoader: boolean;
  certificateDetails: CertificateDetails;
  numberOfToken?: number;
  error: boolean;
  fetchLoading: boolean;
  email: string;
  refetchSerialNumber: (serialNumber: string) => void;
  setEmail: (email: string) => void;
}

function InitiateBridgingModal({
  initializeModalOpen,
  forwardHandler,
  cancelHandler,
  forwardButtonName,
  serialNumber,
  setSerialNumber,
  isClipLoader,
  certificateDetails,
  error,
  fetchLoading,
  email,
  setEmail,
  refetchSerialNumber,
}: InitializeBridgeModelProps) {
  const [selectedRegistry, setSelectedRegistry] = useState<number>(-1);
  const [selectedAccessOption, setSelectedAccessOption] = useState<number>(-1);
  // const [serialNumber, setSerialNumber] = useState<string>();

  const nftId = useEmptyBatchNft();

  const isSubmitDisable = useMemo<boolean>(() => {
    switch (initializeModalOpen) {
      case InitializeModal.INITIATE: {
        if (selectedRegistry !== -1 && selectedAccessOption === 0) {
          return false;
        }
        break;
      }
      case InitializeModal.TRANSFER: {
        return false;
      }
      case InitializeModal.BRIDGE: {
        if (
          String(serialNumber)?.length > 5 &&
          isValidEmailAddress(String(email))
        ) {
          return false;
        }
        break;
      }
      case InitializeModal.SUBMIT: {
        if (Number(nftId) >= 1 && String(serialNumber)?.length > 0) {
          return false;
        }
        break;
      }
      case InitializeModal.AFTERSUBMIT: {
        break;
      }

      default: {
        return true;
      }
    }

    return true;
  }, [
    nftId,
    selectedRegistry,
    selectedAccessOption,
    initializeModalOpen,
    email,
    serialNumber,
  ]);
  return (
    <div className="initiate_modal_Background">
      <div className="initiate_modal_Container">
        <div className="initiate_modal_header">
          <span
            className={
              initializeModalOpen === InitializeModal.INITIATE
                ? "initiate_nav_underling"
                : ""
            }
          >
            1. Initiate
          </span>
          <span
            className={
              initializeModalOpen === InitializeModal.TRANSFER
                ? "initiate_nav_underling"
                : ""
            }
          >
            2. Transfer
          </span>
          <span
            className={
              initializeModalOpen === InitializeModal.BRIDGE
                ? "initiate_nav_underling"
                : ""
            }
          >
            3. Bridge
          </span>
          <span
            className={
              initializeModalOpen === InitializeModal.SUBMIT
                ? "initiate_nav_underling"
                : ""
            }
          >
            4. Submit
          </span>
        </div>
        {initializeModalOpen === InitializeModal.INITIATE && (
          <InitiatePage
            selectedRegistry={selectedRegistry}
            selectedAccessOption={selectedAccessOption}
            setSelectedRegistry={setSelectedRegistry}
            setSelectedAccessOption={setSelectedAccessOption}
          />
        )}
        {initializeModalOpen === InitializeModal.TRANSFER && <TransferPage />}
        {initializeModalOpen === InitializeModal.BRIDGE && (
          <BridgePage
            fetchLoading={fetchLoading}
            error={error}
            email={email}
            setEmail={setEmail}
            serialNumber={serialNumber}
            setSerialNumber={setSerialNumber}
            refetchSerialNumber={refetchSerialNumber}
          />
        )}
        {initializeModalOpen === InitializeModal.SUBMIT && (
          <SubmitPage
            token={certificateDetails?.quantity}
            project={{
              projectName: certificateDetails?.projectName,
              projectId: certificateDetails?.projectId,
              country: certificateDetails?.location,
            }}
            vintage={certificateDetails?.vintage}
          />
        )}
        {initializeModalOpen === InitializeModal.AFTERSUBMIT && (
          <AfterSubmitPage
            close={() => {
              forwardHandler();
              cancelHandler();
            }}
          />
        )}

        {initializeModalOpen !== InitializeModal.AFTERSUBMIT && (
          <div className="initiate_modal_footer">
            <button onClick={() => cancelHandler()} id="cancelBtn">
              Cancel
            </button>

            {initializeModalOpen === InitializeModal.BRIDGE && (
              <button>I need Help</button>
            )}
            <button
              type="submit"
              onClick={() => forwardHandler()}
              disabled={isSubmitDisable}
            >
              {isClipLoader ? (
                <>
                  <ClipLoader color="white" size={20} />
                  &nbsp; {forwardButtonName}
                </>
              ) : (
                forwardButtonName
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default InitiateBridgingModal;
