export interface CarbonRegisty {
  name: string;
}

export const carbonRegistries: CarbonRegisty[] = [{ name: "Verra (VCS)" }];

export const carbonRegistryWalletOption: string[] = [
  "Yes I have access to the registry",
  "No I don't have access to the registry",
];
