import React, { ChangeEvent, useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import "./tokenModal.css";
import { AllowedTokenMetdata } from "../../store/marketplace";
import BCO2 from "../../assets/icons/BCO2.png";
import CustomTooltip from "../ToolTip/CustomTooltip";

interface TokenModalProps {
  setTokenOpenModal: (open: boolean) => void;
  setSearchVal: (search: string) => void;
  tokenList: AllowedTokenMetdata[];
  setSelectedToken: (token: AllowedTokenMetdata) => void;
  searchVal: string;
}

const TokenModal: React.FC<TokenModalProps> = ({
  setSelectedToken,
  setTokenOpenModal,
  setSearchVal,
  tokenList,
  searchVal,
}) => {
  const filteredToken = useMemo(() => {
    return tokenList.filter((el) => {
      if (searchVal === "" || searchVal === null || searchVal === undefined) {
        return el;
      } else if (
        el?.projectName?.toLowerCase().includes(searchVal?.toLowerCase())
      ) {
        return el;
      }
    });
  }, [searchVal, tokenList]);

  return (
    <>
      <div className="token_modal_background">
        <div className="token_modal_Container">
          <>
            <div className="token-head">Select a token </div>
            <div className="token_modal_body">
              <div className="search_bar">
                <div className="search_inner">
                  <AiOutlineSearch size={30} />
                  <input
                    type="text"
                    name="search"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setSearchVal(e.target.value)
                    }
                    placeholder="Search name or paste address"
                  />
                </div>
              </div>
              <div className="token_img">
                {filteredToken?.map((el) => (
                  <div
                    className="img_box"
                    key={el.tokenAddress}
                    onClick={() => {
                      setTokenOpenModal(false);
                      setSelectedToken(el);
                    }}
                  >
                    <div className="left_content">
                      <img src={BCO2} alt="icon" />
                      <div className="label">
                        <p title={el?.tokenName}>
                          {el?.tokenName?.slice(0, 30)}
                        </p>
                      </div>
                    </div>
                    <div className="right_content">
                      <p className="balance_paragraph">{el?.balance1}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="token_modal_footer">
              <button
                className="token_modal_footer_button"
                onClick={() => setTokenOpenModal(false)}
                id="cancelBtn"
              >
                Cancel
              </button>
            </div>
          </>
        </div>
      </div>
      {/* <Modal
        openModal={openTokenModal}
        modalRef={tokenModalRef}
        className={className}
      >
        <div className="title">
          <h2>Select a token</h2>
          <span onClick={() => setTokenOpenModal(false)}>&times;</span>
        </div>
        <div className="search_bar">
          <div className="search_inner">
            <AiOutlineSearch size={30} />
            <input
              type="text"
              name="search"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchVal(e.target.value)
              }
              placeholder="Search name or paste address"
            />
          </div>
        </div>
        <div className="token_img">
          {tokenList
            .filter((val) => {
              if (searchVal === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchVal.toLowerCase())
              ) {
                return val;
              }
            })
            .map((d: Token) => (
              <div
                className="img_box"
                key={d.id}
                onClick={() => {
                  setTokenOpenModal(false);
                  setToken(d);
                }}
              >
                <img src={d.icon} alt="icon" />
                <div className="label">
                  <p>{d.title}</p>
                  <h3>{d.name}</h3>
                </div>
              </div>
            ))}
        </div>
      </Modal> */}
    </>
  );
};

export default TokenModal;
