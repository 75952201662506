import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSerialNumber } from "../../utils/graphql";
import { SerialNumberStatus } from "../../types";
import { setSerialStatus } from ".";
import { AppDispatch, AppState } from "..";

export const useSetSerialStatus = () => {
  const dispatch = useDispatch<AppDispatch>();
  const chainId = 80001;
  useEffect(() => {
    const fetchSerialNumber = async () => {
      const allSerialNumber = await getAllSerialNumber(chainId);

      if (allSerialNumber) {
        const serialNumberStatus: SerialNumberStatus = {};
        const filterdSerialNumber = allSerialNumber.filter(
          (el) => el?.serialNumber !== "-",
        );
        filterdSerialNumber?.map((el) => {
          serialNumberStatus[el?.serialNumber] = el?.status;
        });

        dispatch(setSerialStatus({ serialData: serialNumberStatus }));
      }
    };

    fetchSerialNumber();
  }, [dispatch]);
};

export const useSerialStatus = () => {
  return useSelector((state: AppState) => state.serialStatus.serialData);
};
