import { Interface } from "ethers";
import CARBON_OFFSET_BATCHES_ABI from "../../config/ABI/CarbonOffsetBatches.json";
import BASE_CARBON_TONNE_ABI from "../../config/ABI/BaseCarbonTonne.json";
import TOKEN_ABI from "../../config/ABI/ERC20.json";
import MARKETPLACE_ABI from "../../config/ABI/MARKETPLACE.json";

export const getCarbonOffsetBatchesInterface = () => {
  return new Interface(CARBON_OFFSET_BATCHES_ABI);
};

export const getBaseCarbonTonneInterface = () => {
  return new Interface(BASE_CARBON_TONNE_ABI);
};

export const getTokenInterface = () => {
  return new Interface(TOKEN_ABI);
};

export const getMarketplaceInterface = () => {
  return new Interface(MARKETPLACE_ABI);
};
