import { useCallback, useState } from "react";
import { getCarbonOffsetBatchesInterface } from "../utils/contract/Interface";
import { useWeb3React } from "@web3-react/core";
import { getCarbonOffsetBatchesEncodedCallData } from "../utils/contract/endcode";
import { carbonOffsetBatcheAddress } from "../config/contracts";
import { gasPrice } from "../utils";
import { sendMail } from "../utils/mail";

export enum UPDATE_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

interface UpdateBatchResponse {
  updateStatus: UPDATE_STATUS;
  triggerUpdateBatch: (
    tokenId: number,
    serialNumber: string,
    quantity: number,
    uri: string,
    email: string,
  ) => Promise<boolean | undefined>;
  transactionHash: string;
}

export const useUpdatebatch = (): UpdateBatchResponse => {
  const [updateStatus, setUpdateStatus] = useState<UPDATE_STATUS>();
  const [transactionHash, setTransactionHash] = useState<string>();
  const iface = getCarbonOffsetBatchesInterface();
  const { library, account, chainId } = useWeb3React();
  const carbonOffsetBatchAddress = carbonOffsetBatcheAddress[Number(chainId)];

  const triggerUpdateBatch = useCallback(
    async (
      tokenId: number,
      serialNumber: string,
      quantity: number,
      uri: string,
      email: string,
    ) => {
      if (
        !iface ||
        !account ||
        !carbonOffsetBatchAddress ||
        !quantity ||
        !serialNumber ||
        !tokenId ||
        !uri
      )
        return;
      try {
        setUpdateStatus(UPDATE_STATUS.LOADING);

        const callData = getCarbonOffsetBatchesEncodedCallData(
          iface,
          "updateBatchWithData",
          [tokenId, serialNumber, quantity, uri],
        );

        // estimate gas on
        let gasLimit = await library.getSigner().estimateGas({
          from: account,
          to: carbonOffsetBatchAddress,
          data: callData,
          value: 0,
        });

        // gas price
        let gas_price = await gasPrice(library);

        const update = await library.getSigner().sendTransaction({
          from: account,
          to: carbonOffsetBatchAddress,
          data: callData,
          gasLimit,
          gasPrice: gas_price,
          value: 0,
        });

        const tx = await update.wait(2);
        setUpdateStatus(UPDATE_STATUS.COMPLETE);
        setTransactionHash(tx?.transactionHash);
        sendMail(
          email,
          `Batch successfully submitted for bridging`,
          `${serialNumber} is successfully submitted for Tokenisation with buckmint`,
        );
        return true;
      } catch (err) {
        if (err instanceof Error) {
          setUpdateStatus(UPDATE_STATUS.ERROR);
          console.log("useUpdatebatch failed", err.message);
        }
        return false;
      }
    },
    [account, iface, carbonOffsetBatchAddress, library],
  );

  return {
    /** @ts-ignore */
    updateStatus,
    /** @ts-ignore */
    transactionHash,
    triggerUpdateBatch,
  };
};
