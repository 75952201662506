import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import CARON_OFFSET_BATCHES_ABI from "../config/ABI/CarbonOffsetBatches.json";
import TOKEN_ABI from "../config/ABI/ERC20.json";
import BASE_CARBON_TONNE from "../config/ABI/BaseCarbonTonne.json";
import CARBON_OFFSET_FACTORY from "../config/ABI/CARBON_OFFSET_FACTORY.json";
import {
  baseCarbonTonne,
  carbonOffsetBatcheAddress,
  carbonOffsetFactory,
} from "../config/contracts";
import { useAppChainId } from "../store/application/hook";

export const useTokenContract = (address: string) => {
  return useContract(TOKEN_ABI, address);
};

export const useContract = (ABI: any, address: string) => {
  const { library, chainId } = useWeb3React();
  return useMemo(() => {
    if (!address || !library || !chainId) return null;
    return new Contract(address, ABI, library);
  }, [ABI, address, library, chainId]);
};

export const useCarbonnOffsetBatchesContract = (): Contract | null => {
  const { chainId } = useWeb3React();
  return useContract(
    CARON_OFFSET_BATCHES_ABI,
    carbonOffsetBatcheAddress[Number(chainId)],
  );
};

export const useBaseCarbonTonneContract = () => {
  const appChainId = useAppChainId();
  return useContract(BASE_CARBON_TONNE, baseCarbonTonne[appChainId]);
};

export const useCarbonOffsetFactoryContract = () => {
  const appChainId = useAppChainId();
  return useContract(CARBON_OFFSET_FACTORY, carbonOffsetFactory[appChainId]);
};
