import { BigNumber } from "@ethersproject/bignumber";
import { Web3Provider } from "@ethersproject/providers";
import { parseUnits, formatUnits } from "@ethersproject/units";
import { Contract } from "ethers";

export const miniWalletAddress = (
  address: string,
  chars = 4,
): string | null => {
  if (!address) return null;
  return `${address.substring(0, chars + 2)}......${address.substring(
    42 - chars,
  )}`;
};

export const generateRandomNumber = (maxNumber: number) => {
  return Math.floor(Math.random() * maxNumber);
};

export const gasPrice = async (library: Web3Provider): Promise<BigNumber> => {
  return await library.getGasPrice();
};

export function isValidEmailAddress(address: string) {
  return !!address.match(/.+@.+/);
}

export const unitParser = (
  units: string | number,
  decimals: number = 18,
): BigNumber => {
  if (!units) return null;
  return parseUnits(units.toString(), decimals ? decimals : 18);
};

export const unitFormatter = (
  units: string | BigNumber,
  decimals: number = 18,
): number => {
  if (!units) return null;
  return Number(formatUnits(units, decimals ? decimals : 18));
};

export const estimatedGas = async (
  contract: Contract,
  method: string,
  methodParams: any[],
  account: string,
): Promise<BigNumber> => {
  console.log("gasEstemation", contract, method, methodParams);
  const gasEstemation = await contract.estimateGas[method](...methodParams, {
    from: account,
  });
  console.log("gasEstemation", gasEstemation);
  return gasEstemation;
};
