import React, { ReactElement } from "react";
import { useOrders } from "../../../store/marketplace/hook";
import BCO2 from "../../../assets/icons/BCO2.png";
import { OrdersMetaData } from "../../../store/marketplace";
import ToolTip from "../../../components/ToolTip/ToolTip";
import CustomTooltip from "../../../components/ToolTip/CustomTooltip";

interface BuySectionProps {
  setOpenModal: (openModal: boolean) => void;
  setSelectedToBuy: (order: OrdersMetaData) => void;
  allListedOrder: OrdersMetaData[];
}

const BuySection: React.FC<BuySectionProps> = ({
  setOpenModal,
  setSelectedToBuy,
  allListedOrder,
}: BuySectionProps): ReactElement => {
  return (
    <>
      <div className="card_wrapper">
        {allListedOrder?.map((el) => (
          <div className="tab_cards" key={el?.id}>
            <div className="cards">
              <div className="card_part">
                <img src={BCO2} alt="logo" />
                <h2 className="symbol">BPT</h2>
              </div>
              <div className="card_part">
                <p className="id">
                  Order Id <span>i</span>
                </p>
                <p className="id">{el?.orderId}</p>
              </div>
              {/* <div className="card_part">
                <p className="id">
                  BCO2 <span>i</span>
                </p>
                <p className="id">
                  {el?.tokenAddress?.slice(0, 7)}...............
                  {el?.tokenAddress?.slice(
                    el?.tokenAddress?.length - 7,
                    el?.tokenAddress?.length
                  )}
                </p>
              </div> */}
              {/* <div className="card_part">
                <p className="project_name">
                  Project Name <span>i</span>
                </p>
                <p className="project_name">
                  <CustomTooltip text={el?.projectName}>
                    {
                      `${el?.projectName?.split(" ").slice(0, 3).join(" ")}......`
                    }
                  </CustomTooltip>
                </p>
              </div> */}
              {/* <div className="card_part">
                <p className="vintage">
                  Vintage <span>i</span>
                </p>
                <p className="vintage">{el?.projectVintage}</p>
              </div> */}
              <div className="card_part">
                <p className="price">
                  Price <span>i</span>
                </p>
                <p className="price">${el?.price}</p>
              </div>
              <div className="card_part">
                <p className="amount">
                  Available Token <span>i</span>
                </p>
                <p className="amount">{el?.quantity}</p>
              </div>
              <button
                className="card_btn btn"
                type="button"
                onClick={() => {
                  setOpenModal(true);
                  setSelectedToBuy(el);
                }}
              >
                BUY
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BuySection;
