import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Certificate } from "../../types";

export interface Certificates {
  certificateEntries: Certificate;
}

const initialBatches: Certificates = {
  certificateEntries: {},
};

const certificateSlice = createSlice({
  name: "certificate",
  initialState: initialBatches,
  reducers: {
    setCertificate(state, { payload }: PayloadAction<Certificate>) {
      state.certificateEntries = payload;
      return state;
    },
  },
});

export const { setCertificate } = certificateSlice.actions;

export default certificateSlice.reducer;
