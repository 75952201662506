import { useCallback, useState } from "react";
import { getBaseCarbonTonneInterface } from "../utils/contract/Interface";
import { useWeb3React } from "@web3-react/core";
import { getBaseCarbonTonneCallData } from "../utils/contract/endcode";
import { baseCarbonTonne } from "../config/contracts";
import { gasPrice, unitParser } from "../utils";

export enum RETIRE_STATUS {
  LOADING,
  COMPLETE,
  ERROR,
}

interface DepositResponse {
  retireStatus: RETIRE_STATUS;
  triggerRetire: (
    amount: number,
    projectVinatgeTokenId: number,
  ) => Promise<void>;
  transactionHash: string;
}

export const useRetire = (): DepositResponse => {
  const [retireStatus, setRetireStatus] = useState<RETIRE_STATUS>();
  const [transactionHash, setTransactionHash] = useState<string>();
  const iface = getBaseCarbonTonneInterface();
  const { library, account, chainId } = useWeb3React();
  const baseCarbonTon = baseCarbonTonne[Number(chainId)];

  const triggerRetire = useCallback(
    async (amount: number, projectVinatgeTokenId: number) => {
      if (!iface || !account || !baseCarbonTon) return;
      try {
        setRetireStatus(RETIRE_STATUS.LOADING);

        const callData = getBaseCarbonTonneCallData(iface, "retire", [
          unitParser(amount).toString(),
          projectVinatgeTokenId,
        ]);

        // estimate gas on
        let gasLimit = await library.getSigner().estimateGas({
          from: account,
          to: baseCarbonTon,
          data: callData,
          value: 0,
        });

        // gas price
        let gas_price = await gasPrice(library);

        const retire = await library.getSigner().sendTransaction({
          from: account,
          to: baseCarbonTon,
          data: callData,
          gasLimit,
          gasPrice: gas_price,
          value: 0,
        });

        const tx = await retire.wait(2);
        setRetireStatus(RETIRE_STATUS.COMPLETE);
        setTransactionHash(tx?.transactionHash);
      } catch (err) {
        setRetireStatus(RETIRE_STATUS.ERROR);
        if (err instanceof Error) {
          console.log("useRetire failed", err?.message);
        }
      }
    },
    [account, iface, baseCarbonTon, library],
  );

  return {
    /** @ts-ignore */
    retireStatus,
    /** @ts-ignore */
    transactionHash,
    triggerRetire,
  };
};
