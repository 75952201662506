import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMintedProjects } from "../../utils/graphql";
import { MintedProjectsWithVintage } from "../../types";
import { setProjects } from ".";
import { AppState } from "..";

export const useSetProjects = () => {
  const dispatch = useDispatch();

  const chainId = 80001;

  useEffect(() => {
    const fetchProjects = async () => {
      const mintedProjects = await getMintedProjects(chainId).then(
        (value) => value,
      );
      if (mintedProjects) {
        let projects: MintedProjectsWithVintage = {};

        mintedProjects?.map((el) => {
          projects[Number(el?.projectId)] = el?.tokenId;
        });

        dispatch(setProjects(projects));
      }
    };

    fetchProjects();
  }, [dispatch]);
};

export const useProjects = () => {
  return useSelector((state: AppState) => state.projects.project);
};
