import React from "react";
import BCO2 from "../../../assets/icons/BCO2.png";
import { OrdersMetaData } from "../../../store/marketplace";
import { BuckmintCo2Batch } from "../../../types";

interface SellCardProps {
  el: OrdersMetaData;
}

const SellCard = ({ el }: SellCardProps) => {
  const {
    orderId,
    tokenAddress,
    projectName,
    price,
    projectVintage,
    quantity,
  } = el;

  console.log("el", el);
  return (
    <div className="sell_card">
      <div className="card_part">
        <h2 className="symbol">BPT</h2>
        <img src={BCO2} alt="logo" />
      </div>
      <div className="card_part">
        <p className="id">
          Order Id <span>i</span>
        </p>
        <p className="id">{el?.orderId}</p>
      </div>
      {/* <div className="card_part">
        <p className="id">
          BCO2 <span>i</span>
        </p>
        <p className="id">
          {el?.tokenAddress?.slice(0, 7)}...............
          {el?.tokenAddress?.slice(
            el?.tokenAddress?.length - 7,
            el?.tokenAddress?.length
          )}
        </p>
      </div> */}
      {/* <div className="card_part">
        <p className="project_name">
          Project Name <span>i</span>
        </p>
        <p className="project_name">{el?.projectName}</p>
      </div> */}
      {/* <div className="card_part">
        <p className="vintage">
          Vintage <span>i</span>
        </p>
        <p className="vintage">{el?.projectVintage}</p>
      </div> */}
      <div className="card_part">
        <p className="price">
          Price <span>i</span>
        </p>
        <p className="price">${price}</p>
      </div>
      <div className="card_part">
        <p className="amount">
          Available Token <span>i</span>
        </p>
        <p className="amount">{el?.quantity}</p>
      </div>
    </div>
  );
};

export default SellCard;
