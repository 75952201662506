import Bco2 from "../../../../assets/icons/BCO2.png";
import "./AfterSubmitPage.css";

interface AfterSubmitPageProps {
  close: () => void;
}

const AfterSubmitPage = ({ close }: AfterSubmitPageProps) => {
  return (
    <div className="after_submit_page">
      <img src={Bco2} alt="" />
      <h1>Success! Your batch is ready for review</h1>
      <p>
        Batch #1581 has been successfully submitted and is waiting to be
        reviewed by the Buckmint team. The current average waiting time Is 12h.
        We will notify you as soon as there are any news. In the meantime you
        will find your pending batch on the Overview and Bridge page.
      </p>
      <button onClick={close}>Close</button>
    </div>
  );
};

export default AfterSubmitPage;
