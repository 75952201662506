import { carbonOffsetBatcheAddress } from "../config/contracts";
import { Batch, RetirementStatus } from "../graphql/types";
import { getMulticallOwner } from "./multicall/batches";

export const formatOffsetBatches = async (
  batches: Batch[],
  chainId: number,
  account: string,
) => {
  const tokenIds: string[] = batches?.map((el) => {
    return el?.id;
  });

  const getOwners = await getMulticallOwner(
    chainId,
    carbonOffsetBatcheAddress[chainId],
    tokenIds,
  );

  const formatBatches = batches?.map((el) => {
    return {
      id: String(Number(el?.id)),
      balance1: el?.quantity,
      balance2: el?.quantity?.toString(),
      projectName: el?.serialNumber,
      projectDate: el?.id,
      status:
        getOwners?.[parseInt(el?.id)]?.toLowerCase() === account?.toLowerCase()
          ? el?.status
          : RetirementStatus.Closed,
      action: "Attach Serial",
      comments: [el?.comments],
    };
  });

  return formatBatches;
};
