import MintLogo from "../../assets/icons/BCO2.png";
import "./styles.css";
import { DEPOSIT_STATUS } from "../../hooks/useDeposit";
import { ClipLoader } from "react-spinners";
import {
  ApprovalTransactionStatus,
  useAllowance,
  useApproval,
} from "../../hooks/useApprove";
import { useWeb3React } from "@web3-react/core";
import { baseCarbonTonne } from "../../config/contracts";
import { unitParser } from "../../utils";

interface MintSCTModalProps {
  vintageYear: string;
  balance: number;
  projectName: string;
  setOpenDeposit: (openDeposit: boolean) => void;
  buckmintPoolTokenHandler: () => void;
  depositAmount: number;
  setDepositAmount: (amount: number) => void;
  depositStatus: DEPOSIT_STATUS;
  selectedBCO2: string;
}

const MintSCTModal = ({
  vintageYear,
  projectName,
  balance,
  setOpenDeposit,
  depositAmount,
  setDepositAmount,
  buckmintPoolTokenHandler,
  depositStatus,
  selectedBCO2,
}: MintSCTModalProps) => {
  const { chainId } = useWeb3React();

  const allowedAmount = useAllowance(baseCarbonTonne?.[chainId], selectedBCO2);

  const { isApprovalRequired, triggeredApproval, approvalTxStatus } =
    useApproval(
      allowedAmount ? allowedAmount : unitParser(1),
      unitParser(depositAmount ? depositAmount : 1),
      baseCarbonTonne?.[chainId],
      selectedBCO2,
    );

  return (
    <div className="mint_sct_modal_background">
      <div className="mint_sct_modal_Container">
        {depositStatus === DEPOSIT_STATUS.COMPLETE ? (
          <>
            <div className="mint-head">Congratulations!!!</div>
            <div className="mint_sct_modal_body">
              <div className="font-h3">
                You have successfully deposited {depositAmount} BCO2 tokens into
                the BPT Carbon Bundle to mint {depositAmount} BPT Tokens. The
                newly minted BPT tokens have been transferred into your wallet.
              </div>
              <div className="mint-summary">
                <div className="font-h2">Summary</div>
                <div className="summary-table font-h4">
                  <div className="table-left">Project Name</div>
                  <div className="table-right">{projectName}</div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">Vintage</div>
                  <div className="table-right">{vintageYear}</div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">
                    Total deposit to BPT Carbon Bundle
                  </div>
                  <div className="table-right">{depositAmount} BCO2</div>
                </div>
              </div>
            </div>
            <div className="mint_sct_modal_footer">
              <button
                className="mint_sct_modal_footer_button filled_button"
                onClick={() => setOpenDeposit(false)}
                id="cancelBtn"
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="mint-head">Mint BPT </div>
            <div className="mint_sct_modal_body">
              <div className="font-h2">Token Bundle</div>
              <div className="font-h3">
                Select the BCO2 token and the quantity that you want to deposit
                into bundle to mint BPT
              </div>
              <div className="mint-section">
                <img
                  src={MintLogo}
                  width="54px"
                  height="54px"
                  alt="mint-logo"
                />
                <div className="mintcard">
                  <div className="font-h3">BCO2</div>
                  <div className="font-h2">BCO2-VCS-1705-2019</div>
                </div>
                <div className="mintcard">
                  <div className="font-h3">Balance</div>
                  <div className="font-h2">{balance}</div>
                </div>
                <div className="mintcard">
                  <div className="font-h3">Deposit to bundle</div>
                  <div className="font-h2">
                    <input
                      value={depositAmount}
                      style={{ paddingLeft: "5px" }}
                      onChange={(e) => setDepositAmount(Number(e.target.value))}
                      name="deposit-amount"
                      placeholder="Click to enter the quantity"
                    />
                  </div>
                </div>
              </div>
              <div className="mint-summary">
                <div className="font-h2">Summary</div>
                <div className="summary-table font-h4">
                  <div className="table-left">Project Name</div>
                  <div className="table-right">{projectName}</div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">Vintage</div>
                  <div className="table-right">{vintageYear}</div>
                </div>
                <div className="summary-table font-h4">
                  <div className="table-left">
                    Total deposit to BPT Carbon Bundle
                  </div>
                  <div className="table-right">{depositAmount} BCO2</div>
                </div>
              </div>
            </div>
            <div className="mint_sct_modal_footer">
              <button
                className="mint_sct_modal_footer_button"
                onClick={() => setOpenDeposit(false)}
                id="cancelBtn"
              >
                Close
              </button>
              {isApprovalRequired ? (
                <button
                  id="mintsct"
                  className="mint_sct_modal_footer_button filled_button"
                  onClick={() => triggeredApproval()}
                >
                  {approvalTxStatus ===
                  ApprovalTransactionStatus.APPROVAL_LOADING ? (
                    <>
                      <ClipLoader color="white" size={20} />
                      &nbsp; {`Approve`}
                    </>
                  ) : (
                    `Approve`
                  )}
                </button>
              ) : (
                <button
                  id="mintsct"
                  className="mint_sct_modal_footer_button filled_button"
                  disabled={true}
                >
                  {" "}
                  Approve
                </button>
              )}
              <button
                id="mintsct"
                className={
                  isApprovalRequired
                    ? "mint_sct_modal_footer_button"
                    : "mint_sct_modal_footer_button filled_button"
                }
                onClick={() => buckmintPoolTokenHandler()}
                disabled={isApprovalRequired}
              >
                {depositStatus === DEPOSIT_STATUS.LOADING ? (
                  <>
                    <ClipLoader color="white" size={20} />
                    &nbsp; {`Mint BPT`}
                  </>
                ) : (
                  `Mint BPT`
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MintSCTModal;
