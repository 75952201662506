import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SerialNumberStatus } from "../../types";

export interface ApprovedSerial {
  serialData: SerialNumberStatus;
}

const initialSerialStatus: ApprovedSerial = {
  serialData: {},
};

const serialStatusSlice = createSlice({
  name: "serialStatus",
  initialState: initialSerialStatus,
  reducers: {
    setSerialStatus(
      state,
      { payload }: PayloadAction<{ serialData: SerialNumberStatus }>,
    ) {
      state.serialData = payload.serialData;
      return state;
    },
  },
});

export const { setSerialStatus } = serialStatusSlice.actions;

export default serialStatusSlice.reducer;
