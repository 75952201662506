import axios from "axios";
import { spvUrl } from "../config";

export const sendMail = (to: string, subject: string, text: string) => {
  axios
    .post(`${spvUrl}/mail/send-email`, {
      to,
      subject,
      text,
    })
    .then(() => console.log("mail sent"))
    .catch((data) => console.log(data));
};
