import { useSetCertificate } from "../../store/certificate/hook";
import {
  useSetAllowedToken,
  useSetMarketplaceOrder,
} from "../../store/marketplace/hook";
import { useSetProjects } from "../../store/projects/hooks";
import {
  useSetProtocolData,
  useSetTotalBPT,
  useSetTotalRetired,
} from "../../store/protocol/hook";
import { useSetSerialStatus } from "../../store/serialStatus/hook";
import {
  useSetMintBC02Batches,
  useSetBCO2Tokens,
  useSetBPTTokens,
} from "../../store/tokens/hook";

export function QueryResolvers() {
  useSetMintBC02Batches();
  useSetBCO2Tokens();
  useSetBPTTokens();
  useSetTotalBPT();
  useSetProtocolData();
  useSetTotalRetired();
  useSetProjects();
  useSetCertificate();
  useSetSerialStatus();
  useSetAllowedToken();
  useSetMarketplaceOrder();

  return null;
}
