import BuckmintCo2Batches from "../../components/BuckmintCo2Batches/BuckmintCo2Batches";
import CarbonBundleTokens from "../../components/CarbonBundleTokens/CarbonBundleTokens";
import Connect from "../../components/ConnectWallet/ConnectWallet";
import Initiate from "../../components/Initiate/Initiate";
import OpenBco2Batches from "../../components/OpenBco2Batches/OpenBco2Batches";
import Refresh from "../../components/Refresh/Refresh";
import Testnet from "../../components/Testnet/Testnet";
import ToolTip from "../../components/ToolTip/ToolTip";
import { useProtocolData } from "../../store/protocol/hook";
import "./Overview.css";
function Overview() {
  const protocolData = useProtocolData();
  return (
    <div className="home">
      <div className="section1">
        <Testnet />
        <Connect />
      </div>
      <div className="token-section">
        <div className="tokencard">
          <div className="tokenname">
            <div className="tokennamehead">
              <span>Total Carbon Bridged (tCO2)</span>
              <ToolTip data="The total amount of offsets bridged on chain using the Buckmint carbon bridge." />
            </div>
          </div>
          <div className="tokenvalue">{protocolData?.totalBridged}</div>
        </div>
        <div className="tokencard">
          <div className="tokenname">
            <div className="tokennamehead">
              <span>Total Carbon Supply</span>
              <ToolTip data="The total number of BPT deposited from underlying carbon tons." />
            </div>
          </div>
          <div className="tokenvalue">{protocolData?.totalBPT}</div>
        </div>
        <div className="tokencard">
          <div className="tokenname">
            <div className="tokennamehead">
              <span>Total Liquidity</span>
              <ToolTip data="The total number of BPT liquidity on all exchanges." />
            </div>
          </div>
          <div className="tokenvalue">${protocolData?.totalBridged * 10}</div>
        </div>
        <div className="tokencard">
          <div className="tokenname">
            <div className="tokennamehead">
              <span>Total Carbon Retired (tCO2e)</span>
              <ToolTip data="The total number of offset token" />
            </div>
          </div>
          <div className="tokenvalue">{protocolData?.totalRetired}</div>
        </div>
      </div>
      <div className="section3">
        <h1>My Carbon Assets</h1>
        <div>
          <Refresh />
          <Initiate />
        </div>
      </div>
      <div className="section4">
        <OpenBco2Batches heading="Open BCO2 Batches" value={32} />
        <BuckmintCo2Batches heading="Buckmint CO2 Tokens (BCO2)" value={32} />
        <CarbonBundleTokens heading="Carbon Bundle Tokens" value={32} />
      </div>
    </div>
  );
}

export default Overview;
