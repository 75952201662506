import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import QuestionMark from "../../assets/icons/question_mark.png";
import "./ToolTip.css";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#C5EEE6",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#C5EEE6",
    color: "#121827",
    padding: "15px",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
}));

const ToolTip = (data: any) => {
  return (
    <BootstrapTooltip arrow placement="top-end" title={data?.data}>
      <img src={QuestionMark} className="tooltip_img" alt="question-mark" />
    </BootstrapTooltip>
  );
};

export default ToolTip;
