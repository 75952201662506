import React from "react";
import refereshIcon from "../../assets/icons/refresh.png";
import "./Refresh.css";

function Refresh() {
  function refreshPage() {
    window.location.reload();
  }

  return (
    <div onClick={() => refreshPage()} className="refresh">
      <img src={refereshIcon} alt="" />
      <span>Refresh</span>
    </div>
  );
}

export default Refresh;
