import { useCallback, useState } from "react";
import { gasPrice, unitParser } from "../utils";
import { useWeb3React } from "@web3-react/core";
import { getTokenCallData } from "../utils/contract/endcode";
import { getMarketplaceInterface } from "../utils/contract/Interface";
import { marketPlaceContract } from "../config/marketplace";

// sell transactions
export enum SellTransactionStatus {
  /** on loading */
  SELL_LOADING,
  /** on confirmed */
  SELL_CONFIRMED,
  /** on error */
  SELL_ERROR,
}

export interface SellResponse {
  /** transaction status */
  sellTxStatus: SellTransactionStatus;
  /** sell callback function */
  triggeredSell: () => Promise<void>;
  /** tx */
  tx?: boolean;
  /** transaction hash */
  transactionHash?: string;
}

export const useSell = (
  /** bco2 token address */
  bco2Address: string,
  /** sell token Id */
  price: number,
  /** qunatity to list for sell */
  quantity: number,
): SellResponse => {
  let [sellTxStatus, setSellTxStatus] = useState<SellTransactionStatus>();
  let [transactionHash, setTransactionHash] = useState<string>();
  const { account, library, chainId } = useWeb3React();

  const iface = getMarketplaceInterface();
  const marketplaceAddress = marketPlaceContract?.[chainId];

  const triggeredSell = useCallback(async () => {
    try {
      if (
        !account ||
        !bco2Address ||
        !price ||
        !quantity ||
        !marketplaceAddress
      )
        return null;
      // set sell status loading

      setSellTxStatus(SellTransactionStatus.SELL_LOADING);

      const callData = getTokenCallData(iface, "sell", [
        bco2Address,
        unitParser(price, 6)?.toString(),
        unitParser(quantity)?.toString(),
      ]);

      // estimate gas on
      let gasLimit = await library.getSigner().estimateGas({
        from: account,
        to: marketplaceAddress,
        data: callData,
        value: 0,
      });

      // gas price
      let gas_price = await gasPrice(library);

      const sell = await library.getSigner().sendTransaction({
        from: account,
        to: marketplaceAddress,
        data: callData,
        gasLimit,
        gasPrice: gas_price,
        value: 0,
      });

      // waiting atleast two confirmation
      await sell.wait(2);
      setTransactionHash(sell?.transactionHash);
      // set sell transaction status to confirm
      setSellTxStatus(SellTransactionStatus.SELL_CONFIRMED);
    } catch (err) {
      console.log("useSell Failed", err);
      // set error
      setSellTxStatus(SellTransactionStatus.SELL_ERROR);
    }
  }, [
    library,
    account,
    iface,
    marketplaceAddress,
    bco2Address,
    price,
    quantity,
  ]);

  return {
    sellTxStatus,
    triggeredSell,
    transactionHash,
  };
};
