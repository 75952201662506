import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MintedProjectsWithVintage } from "../../types";

export interface Projects {
  project: MintedProjectsWithVintage;
}

const initialProjects: Projects = {
  project: {},
};

const projectSlice = createSlice({
  name: "projects",
  initialState: initialProjects,
  reducers: {
    setProjects(state, { payload }: PayloadAction<MintedProjectsWithVintage>) {
      state.project = payload;
      return state;
    },
  },
});

export const { setProjects } = projectSlice.actions;

export default projectSlice.reducer;
